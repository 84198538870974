import { Button, Icon, IconNameType } from '@octano/global-ui';
import { Trans, useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

type DisplayErrorProps = {
  title?: string;
  body: 'CONNECTION' | 'HTTP_ERROR' | string | (() => JSX.Element);
  retryAction?: () => void;
  loadingAction?: boolean;
  insideCard?: boolean;
  icon?: IconNameType;
  textButton?: string;
  className?: string;
};

/**
 * Componente utilizada para mostrar mensaje de error al cargar información.
 * Se puede agregar botón con acción para reintentar la carga.
 * Titulo por defecto es "No se pudo cargar la información",
 */
const DisplayError = ({
  title,
  body,
  retryAction,
  loadingAction = false,
  insideCard = false,
  icon = 'warning',
  textButton,
  className,
}: DisplayErrorProps) => {
  const prefix = 'common.displayError';
  const { t } = useTranslation();

  const getBody = () => {
    if (body === 'CONNECTION') {
      return t(`${prefix}.errorConnection`);
    } else if (body === 'HTTP_ERROR') {
      return t(`${prefix}.errorUnexpected`);
    }
    return body;
  };

  const Content = () => (
    <div
      style={{ maxWidth: '500px', alignSelf: 'center' }}
      className={className}
    >
      <Row className="text-center py-4 px-3">
        <Col xs={12}>
          <Icon name={icon} color="secondary" size="65px" />
        </Col>
        <Col xs={12} className="pt-4 pb-3">
          <span className="fs-20 text-dark">
            {title || t(`${prefix}.title`)}
          </span>
        </Col>

        <Col xs={12}>
          {typeof body === 'string' ? (
            <p className="fs-18 fw-300">
              <Trans t={t}>{getBody()}</Trans>
            </p>
          ) : (
            body()
          )}
        </Col>

        {retryAction && (
          <Col xs={12} className="py-3 px-1 px-md-3">
            <Button
              text={textButton || t(`${prefix}.retryBtn`)}
              onClick={retryAction}
              loading={loadingAction}
              fullwidth
            />
          </Col>
        )}
      </Row>
    </div>
  );

  if (insideCard) {
    return (
      <Card className="py-4">
        <Content />
      </Card>
    );
  } else {
    return <Content />;
  }
};

export default DisplayError;
