import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'reactstrap';

import Base from '../../components/base/Base';
import CareerSelector from '../../components/careerSelector/CareerSelector';
import TopBar from '../../components/topBar/TopBar';
import { PathsLayouts } from '../../config/routes';
import { CourseHistory } from './parts/CourseHistory';
import StudentGrid from './parts/StudentGrid';

export const CURRICULAR_ADVANCEMENT_TABS_ROUTES = {
  STUDENT_GRID: '/',
  COURSE_HISTORY: '/course-history',
} as const;

const { STUDENT_GRID, COURSE_HISTORY } = CURRICULAR_ADVANCEMENT_TABS_ROUTES;

export default function CurricularAdvancement() {
  const { t } = useTranslation();
  const { type } = useParams<{ type: string }>();
  const history = useHistory();

  const onChangeTab = useCallback(
    (pathname: string) => {
      history.replace(PathsLayouts.curricularAdvancement + `${pathname}`);
      return null;
    },
    [history],
  );

  return (
    <Base>
      <TopBar title={t('curricularAdvancement.title')} />
      <Card className="p-4 mx-3">
        <CareerSelector>
          <h6 className="fs-20 text-uppercase fw-700 tc-primary mt-4">
            {t('curricularAdvancement.subtitle')}
          </h6>
          <p className="fs-18 text-light mb-5 mt-3">
            {t('curricularAdvancement.info')}
          </p>
          <Tabs
            size="md"
            defaultTab={type}
            onSelection={(id) => onChangeTab(id as string)}
          >
            <TabsList>
              <Tab id={STUDENT_GRID}>
                {t('curricularAdvancement.tabs.studentGrid')}
              </Tab>
              <Tab id={COURSE_HISTORY}>
                {t('curricularAdvancement.tabs.courseHistory')}
              </Tab>
            </TabsList>
            <TabPanels>
              <TabPanel id={STUDENT_GRID}>
                <StudentGrid />
              </TabPanel>
              <TabPanel id={COURSE_HISTORY}>
                <CourseHistory />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CareerSelector>
      </Card>
    </Base>
  );
}
