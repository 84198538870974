import {
  FunctionComponent,
  createContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { removeStudyPlanEnrollmentDefault } from '../components/careerSelector/studyPlanEnrollmentDefault';
import SessionExpiredModal from '../components/sessionExpiredModal/SessionExpiredModal';
import userReducer, { DEFAULT_VALUE } from '../reducers/userReducer';
import { UserSessionEvent } from '../types/Auth';
import { UserActionTypes, UserContextType } from '../types/userTypes';
import { getJWT, parseJWT } from '../utils/auth';

export const UserContext = createContext({} as UserContextType);

const UserProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, DEFAULT_VALUE);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);

  // Manejo de datos de sesion tomados del token
  useEffect(() => {
    const handleStorage = () => {
      const token = getJWT();

      if (token) {
        const data = parseJWT(token);
        dispatch({
          type: UserActionTypes.SET_USER_DATA,
          payload: { ...data?.account, permissions: data?.permissions },
        });
        dispatch({ type: UserActionTypes.SET_IS_LOGGED, payload: true });
        dispatch({ type: UserActionTypes.SET_IS_LOGGED, payload: true });
      } else {
        dispatch({ type: UserActionTypes.RESET_STATE });
      }
    };

    // Primer chequeo al cargar la app
    handleStorage();

    // Para escuchar en el futuro los cambios del token
    window.addEventListener('storage', handleStorage);

    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, []);

  // Manejo de expiracion de sesion
  useEffect(() => {
    const notifySessionExpiration = () => {
      setShowSessionExpiredModal(true);
      removeStudyPlanEnrollmentDefault();
    };

    window.addEventListener(UserSessionEvent.EXPIRED, notifySessionExpiration);

    return () => {
      window.removeEventListener(
        UserSessionEvent.EXPIRED,
        notifySessionExpiration,
      );
    };
  }, []);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <>
        <SessionExpiredModal
          isOpen={showSessionExpiredModal}
          onConfirm={() => {
            dispatch({ type: UserActionTypes.RESET_STATE });
            setShowSessionExpiredModal(false);
          }}
        />
        {children}
      </>
    </UserContext.Provider>
  );
};

export default UserProvider;
