import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import Base from '../../components/base/Base';
import CareerSelector from '../../components/careerSelector/CareerSelector';
import TopBar from '../../components/topBar/TopBar';
import Reports from './Reports';

export default function ReportsView() {
  const { t } = useTranslation();

  return (
    <Base>
      <TopBar title={t('reports.navTitle')} />
      <Card className="p-4 mx-3" style={{ minHeight: 400 }}>
        <CareerSelector>
          <Reports />
        </CareerSelector>
      </Card>
    </Base>
  );
}
