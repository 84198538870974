import { SelectOptionType } from '@octano/global-ui';

export interface PetitionInf {
  folio: number;

  createdAt: Date;

  studyPlanVersionName: string;

  typeId: number;

  typeName: string;

  status: PetitionStatus;

  title: string;
}

export enum PetitionStatus {
  Received = 'recibida',
  InProcess = 'en_proceso',
  Waiting = 'en_espera',
  Resolved = 'resuelta',
}

export interface CreatePetition {
  studyPlanEnrollmentId: number | string;

  phone: string;

  email: string;

  typeId: number | string;

  title: string;

  description: string;

  doc: File;
}

export interface PetitionData {
  studyPlanEnrollment: SelectOptionType;

  cellPhone: string;

  email: string;

  type: SelectOptionType;

  title: string;

  description: string;

  resolved?: string;

  doc: File;
}

export interface CreatePetitionData {
  email: string;

  cellPhone?: string;

  types: {
    id: number;
    name: string;
  }[];
}

export interface ViewPetition {
  id: number;

  studyPlanEnrollmentId: number;

  phone: string;

  email: string;

  type: {
    id: number;
    name: string;
  };

  title: string;

  description: string;

  resolved?: {
    id: number;
    comment: string;
  };

  doc?: {
    name: string;
    url: string;
  };

  docResponse?: {
    name: string;
    url: string;
  };
}
