import { Button, Icon, Modal, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export default function ConfirmDiscardModal({
  isOpen,
  onConfirm = () => null,
  onCancel = () => null,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center text-center">
        <Icon name="warning" color="primary" size={50} />
        <h2 className="fs-20 fw-600 text-dark my-4">
          {t(`discardEnrollmentChanges.confirmationTitle`)}
        </h2>
        <p className="fs-18 text-medium mb-5">
          {t(`discardEnrollmentChanges.confirmationDescription`)}
        </p>
        <div
          className="d-flex align-items-center flex-wrap justify-content-between w-100"
          style={{ gap: 12 }}
        >
          <Button
            text={t(`common.btnCancel`)}
            onClick={onCancel}
            outlined
            className={isMobile ? 'w-100' : 'flex-grow-1'}
          />
          <Button
            text={t(`common.btnConfirm`)}
            onClick={onConfirm}
            className={isMobile ? 'w-100' : 'flex-grow-1'}
          />
        </div>
      </div>
    </Modal>
  );
}
