import { DetailBox, DetailBoxColumn } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface InterimDegressAuthorizedStudentBoxProps {
  student: string;
  studyPlan: string;
  interimDegree: string;
  schedule: string;
  campus: string;
  period: {
    label: string;
    value: string;
  };
}

const dictPrefix = 'interimDegreeActivitiesDetails.authorizedStudentBox';

export default function InterimDegressAuthorizedStudentBox(
  props: InterimDegressAuthorizedStudentBoxProps,
) {
  const { t } = useTranslation();

  const columns = useMemo<DetailBoxColumn[]>(() => {
    return [
      {
        title: t(`${dictPrefix}.studyPlan`),
        body: props.studyPlan,
      },
      {
        title: t(`${dictPrefix}.schedule`),
        body: props.schedule,
      },
      {
        title: t(`${dictPrefix}.campus`),
        body: props.campus,
      },
    ];
  }, [props, t]);

  return (
    <div className="d-flex justify-content-end">
      <DetailBox columns={columns} />
    </div>
  );
}
