import { Carousel } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarouselItemProps, Spinner } from 'reactstrap';

import { requestCarouselImages } from '../../../api/requests/studentManagement';

export default function CoursesCarousel() {
  const [items, setItems] = useState<CarouselItemProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const getCarouselImages = useCallback(async () => {
    setIsLoading(true);
    const { data } = await requestCarouselImages(1);
    if (data && data.data) {
      setItems(data.data.images.map((item) => ({ src: item.url })));
    } else {
      console.log('carousel: ', t(`common.displayError.errorConnection`));
    }
    setIsLoading(false);
  }, [setItems, setIsLoading, t]);

  useEffect(() => {
    getCarouselImages();
  }, [getCarouselImages]);

  if (isLoading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: '30vh' }}
      >
        <Spinner color="primary" size="40px" />
      </div>
    );
  }

  if (items.length === 0) {
    return <></>;
  }

  return <Carousel items={items} />;
}
