import { Icon, UserMenu, useMobile, useSidenav } from '@octano/global-ui';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { useUserState } from '../../hooks/useUserState';

interface Props {
  title: string;
}

const TopBar = ({ title }: Props) => {
  const isMobile = useMobile();
  const history = useHistory();
  const { toggle: toggleSidenav } = useSidenav();
  const { resetUserState, userData } = useUserState();

  const openManual = () => {
    window.open('/tenant/Manual Estudiante Campus.pdf', '_blank')?.focus();
  };

  const logOut = () => {
    resetUserState();
    history.push('/');
  };

  return (
    <div className="g-tabs-header">
      <div className={clsx('bar', isMobile && 'barMobile')}>
        <Container fluid>
          <Row className="align-items-center justify-content-between">
            {isMobile && (
              <Col xs="auto">
                <span onClick={() => toggleSidenav()}>
                  <Icon name="menu" size="30px" className="menuIcon" />
                </span>
              </Col>
            )}
            {!isMobile && (
              <Col>
                <span className="title">{title}</span>
              </Col>
            )}
            <Col xs="auto">
              <UserMenu
                fullName={userData?.fullname ?? ''}
                email={userData?.email ?? ''}
                menuItems={[
                  {
                    text: 'Manual de Usuario',
                    onClick: openManual,
                  },
                  {
                    icon: 'log_out',
                    text: 'Cerrar sesión',
                    onClick: logOut,
                  },
                ]}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile && (
        <Container fluid>
          <h1 className="title">{title}</h1>
        </Container>
      )}
    </div>
  );
};

export default TopBar;
