import { CourseType } from '../../types/courseRegistrationRequestTypes';
import {
  CampusType,
  FacultyType,
  ModalityType,
  TeacherType,
} from '../../types/courseSearchingTypes';
import { SectionType } from '../../types/sectionType';
import { COURSE_SEARCHING } from '../endpoints';
import request from '../request';

/**
 * Obtiene los Facultades disponibles en toda la universidad
 */
export const requestFaculties = () => {
  return request<FacultyType[]>(COURSE_SEARCHING.FACULTY, {
    method: 'get',
  });
};

/**
 * Obtiene los planes de estudios de una facultad específica
 */
export const requestStudyPlans = (facultyId: number) => {
  return request<{
    data: [];
  }>(`${COURSE_SEARCHING.STUDY_PLANS}/${facultyId}/all`, {
    method: 'get',
  });
};

/**
 * Obtiene los Campus disponibles en toda la universidad
 */
export const requestCampus = () => {
  return request<CampusType[]>(COURSE_SEARCHING.CAMPUS, {
    method: 'get',
  });
};

/**
 * Obtiene los Campus disponibles en toda la universidad
 */
export const requestStudyPlansFilter = (
  idFaculty?: string | undefined,
  idCampus?: string | undefined,
  idStudyPlan?: string | undefined,
  idTeacher?: string | undefined,
  idSemester?: string | undefined,
  idModality?: string | undefined,
) => {
  const params: any = {};
  if (idFaculty) params.school = idFaculty;
  if (idCampus) params.campus = idCampus;
  if (idStudyPlan) params.studyPlan = idStudyPlan;
  if (idTeacher) params.professor = idTeacher;
  if (idSemester) params.semestre = idSemester;
  if (idModality) params.modality = idModality;

  return request<any[]>(COURSE_SEARCHING.SEARCH_STUDY_PLANS_FILTER, {
    method: 'get',
    params,
  });
};

/**
 * Obtiene los Campus disponibles en toda la universidad
 */
export const requestCoursesFilter = (
  curriculumId: number,
  currentSemester: string | undefined,
  currentTeacher: string | undefined,
) => {
  const params: any = {};
  params.curriculumId = curriculumId;
  if (currentSemester) params.semestre = currentSemester;
  if (currentTeacher) params.professor = currentTeacher;

  return request<{ data: CourseType[] }>(
    COURSE_SEARCHING.SEARCH_COURSES_FILTER,
    {
      method: 'get',
      params,
    },
  );
};

/**
 * Obtiene los Campus disponibles en toda la universidad
 */
export const requestSectionsFilter = (
  courseId: number,
  currentTeacher: string | undefined,
) => {
  const params: any = {};
  params.courseId = courseId;
  if (currentTeacher) params.professor = currentTeacher;

  return request<{ data: SectionType[] }>(
    COURSE_SEARCHING.SEARCH_SECTIONS_FILTER,
    {
      method: 'get',
      params,
    },
  );
};

/**
 * Obtiene las Modalidades disponibles en toda la universidad
 */
export const requestModality = () => {
  return request<ModalityType[]>(COURSE_SEARCHING.MODALITY, {
    method: 'get',
  });
};

/**
 * Obtiene los Profesores disponibles en toda la universidad
 */
export const requestTeachers = () => {
  return request<{ data: TeacherType[] }>(COURSE_SEARCHING.TEACHERS, {
    method: 'get',
  });
};
