import { Alert } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

const prefix = `interimDegreeActivities`;

export function DisplayInfo(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Col md={12}>
      <Alert
        className="mt-3 mb-2"
        color="info"
        icon="information"
        size="lg"
        text={t(`${prefix}.noActivities`)}
        toggle={() => {}}
      />
    </Col>
  );
}
