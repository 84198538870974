import { Select } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import SectionTitle from '../../../../../components/text/SectionTitle';
import { FieldsStudentFile } from '../../../../../types/studentFileTypes';
import {
  educationalLevelOptions,
  familyGroupOptions,
  parentalSurvivalOptions,
} from '../../../../../utils/selectOptions';

/**
 * Sección de Antecedentes educacionales de los padres
 */
const ParentalEducationSection = () => {
  const { control } = useFormContext<FieldsStudentFile>();
  const prefix = `tuitionContinuity.steps.studentFile.parentalEducationSection`;
  const { t } = useTranslation();

  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={6}>
          <Select
            name="fatherEducationalLevel"
            label={t(`${prefix}.fatherEducationalLevel`)}
            options={educationalLevelOptions}
            isSearchable={false}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={6}>
          <Select
            name="motherEducationalLevel"
            label={t(`${prefix}.motherEducationalLevel`)}
            options={educationalLevelOptions}
            isSearchable={false}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={6}>
          <Select
            name="familyGroup"
            label={t(`${prefix}.familyGroup`)}
            options={familyGroupOptions}
            isSearchable={false}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={6}>
          <Select
            name="parentalSurvival"
            label={t(`${prefix}.parentalSurvival`)}
            options={parentalSurvivalOptions}
            isSearchable={false}
            control={control}
          />
        </Col>
      </Row>
    </>
  );
};

export default ParentalEducationSection;
