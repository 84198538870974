import { InterimDegreeAuthorizedStudentActivitiesDetails } from '../../views/interimDegreeActivities/interfaces/interim-degree-authorized-student-activities-details.interface';
import { InterimDegreeSearchControl } from '../../views/interimDegreeActivities/interfaces/interim-degree-search-controls.interface';
import { INTERIM_DEGREE_PROCESS_ACTIVITIES } from '../endpoints';

import request from '../request';
export const getActivitiesDetails = (params: {
  interimDegreeId: number | string;
}) =>
  request<InterimDegreeAuthorizedStudentActivitiesDetails>(
    INTERIM_DEGREE_PROCESS_ACTIVITIES.DETAILS(params),
  );

export const getActivitiesForm = (params: {
  studyPlanEnrollmentId: number | string;
}) =>
  request<InterimDegreeSearchControl>(
    INTERIM_DEGREE_PROCESS_ACTIVITIES.FORM(params),
  );
