import { STUDENT_MANAGEMENT } from '../../../api/endpoints';
import request from '../../../api/request';
import { StudentScoreSummaryResponse } from '../types';

/**
 *
 * @param studentId
 * @param sectionId
 * @returns
 */
export const getStudentScoreSummary = (
  studentId: number | string,
  sectionId: number | string,
) => {
  return request<StudentScoreSummaryResponse>(
    STUDENT_MANAGEMENT.GET_SCORE_SUMMARY(studentId, sectionId),
    {
      method: 'get',
    },
  );
};
