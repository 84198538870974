import { Button, addToast } from '@octano/global-ui';
import { useCallback } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';

import { requestTuitionContinuitySaveStudentFile } from '../../../../../api/requests/tuitionContinuity';
import ContactSection from '../../../../../components/form/ContactSection';
import { useStepState } from '../../../../../hooks/useStepState';
import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from '../../../../../types/studentFileSectionTypes';
import { FieldsStudentFile } from '../../../../../types/studentFileTypes';
import { formatStudentFileData } from '../utils';
import EducationalBackground from './EducationBackground';
import StudentBackground from './EducationalBackgroundSection';
import EmergencyContactSection from './EmergencyContactSection';
import HealthCareSection from './HealthCareSection';
import ParentalEducationSection from './ParentalEducationSection';

type StudentFileFormProps = {
  backgroundData?: BackgroundSectionType;
  educationalBackground?: EducationalBackgroundType;
  defaultValues?: DefaultValues<FieldsStudentFile>;
};

const StudentFileForm = ({
  backgroundData,
  educationalBackground,
  defaultValues,
}: StudentFileFormProps) => {
  const { nextStep } = useStepState();
  const prefix = 'tuitionContinuity.steps.studentFile';
  const { t } = useTranslation();
  const history = useHistory();
  const methods = useForm<FieldsStudentFile>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const onSubmit = useCallback(
    async (values: FieldsStudentFile) => {
      values.graduationYear = defaultValues?.graduationYear ?? '';
      values.nameHighSchool = defaultValues?.nameHighSchool ?? '';
      const formatData = formatStudentFileData(values);
      const { data, error } = await requestTuitionContinuitySaveStudentFile(
        studyPlanEnrollmentId,
        formatData,
      );
      if (data) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.saveSuccess`),
        });
        nextStep();
      } else if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.saveError`),
        });
      }
    },
    [studyPlanEnrollmentId, nextStep, t, defaultValues],
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {/* SECCIONES DEL FORMULARIO */}
        <StudentBackground data={backgroundData} />
        <EducationalBackground
          educationalBackground={educationalBackground}
          disableInputs={true}
        />

        <ContactSection
          prefix={`${prefix}.academicContactSection`}
          optionalFields={['addressExtra', 'phone']}
        />
        <ContactSection
          prefix={`${prefix}.familyGroupSection`}
          fieldNames={{
            addressStreet: 'addressStreetFamily',
            addressNumber: 'addressNumberFamily',
            addressExtra: 'addressExtraFamily',
            region: 'regionFamily',
            commune: 'communeFamily',
            phone: 'phoneFamily',
            cellphone: 'cellphoneFamily',
            email: 'emailFamily',
          }}
        />
        <EmergencyContactSection />
        <ParentalEducationSection />
        <HealthCareSection />
        <Row className="py-5 justify-content-end">
          <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
            <Button
              type="button"
              outlined
              onClick={() => history.push('/tuition-continuity')}
              text={t(`common.actions.cancel`)}
              loading={methods.formState.isSubmitting}
              fullwidth
            />
          </Col>

          <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
            <Button
              type="submit"
              text={t(`common.actions.next`)}
              loading={methods.formState.isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default StudentFileForm;
