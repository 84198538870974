import {
  SaveStudentFileBody,
  StudentFileResponse,
} from '../../types/studentFileSectionTypes';
import {
  SustainerBody,
  SustainerStepResponse,
} from '../../types/sustainerTypes';
import {
  CurrentStepResponse,
  StudentTuitionContinuityStatusResponse,
} from '../../types/tuitionContinuity';
import { TuitionForbiddenEvent } from '../../utils/tuitionForbiddenEvent';
import {
  FilesUpload,
  GeneralTypes,
} from '../../views/tuitionContinuity/steps/signDocumentation/parts/signDocumentationTypes';
import {
  TariffCalculateResponse,
  TariffData,
} from '../../views/tuitionContinuity/steps/tariff/parts/tariffTypes';
import { TUITION_CONTINUITY } from '../endpoints';
import request, { RequestOptionsType } from '../request';

const tcRequest = async <T>(url: string, options: RequestOptionsType = {}) => {
  const response = await request<T>(url, options);
  const { error } = response;
  if (error && error.data.statusCode === 403) {
    window.dispatchEvent(new TuitionForbiddenEvent());
  }
  return response;
};

export const requestStudentTuitionContinuityStatus = (
  studyPlanEnrollmentId: number,
) => {
  return tcRequest<StudentTuitionContinuityStatusResponse>(
    TUITION_CONTINUITY.STUDENT_STATUS(studyPlanEnrollmentId),
    {
      method: 'get',
    },
  );
};
export const requestTuitionContinuityCurrentStep = (
  studyPlanEnrollmentId: number | string,
) =>
  tcRequest<{ data: CurrentStepResponse }>(
    TUITION_CONTINUITY.CURRENT_STEP(studyPlanEnrollmentId),
    {
      method: 'get',
    },
  );

/**********************
 * FICHA DEL ESTUDIANTE
 *********************/

export const requestTuitionContinuityRecoverStudentFile = (
  studyPlanEnrollmentId: number | string,
) =>
  tcRequest<{ data: StudentFileResponse }>(
    TUITION_CONTINUITY.RECOVER_STUDENT_FILE(studyPlanEnrollmentId),
    { method: 'get' },
  );

export const requestTuitionContinuitySaveStudentFile = (
  studyPlanEnrollmentId: number | string,
  data: SaveStudentFileBody,
) =>
  tcRequest<{ data: any }>(
    TUITION_CONTINUITY.SAVE_STUDENT_FILE(studyPlanEnrollmentId),
    { method: 'post', data },
  );

/**********************
 * ARANCEL Y CUOTAS
 *********************/

export const getTariffInfo = async (studyPLanEnrollmentId: number | string) => {
  const url = TUITION_CONTINUITY.TARIFF_STEP(studyPLanEnrollmentId);
  return tcRequest<TariffData>(url);
};

export const getTariffInfoCalculate = async (
  studyPLanEnrollmentId: number,
  data: {
    installments: number;
    payday: number;
    amount: number;
  },
) => {
  const url = TUITION_CONTINUITY.TARIFF_CALCULATE(studyPLanEnrollmentId);
  return tcRequest<TariffCalculateResponse>(url, {
    method: 'POST',
    data,
  });
};

export const saveTariffInfo = (
  studyPLanEnrollmentId: number | string,
  quotaId: number,
) => {
  const url = TUITION_CONTINUITY.TARIFF_STEP_SAVE(studyPLanEnrollmentId);
  return tcRequest<any>(url, {
    method: 'POST',
    data: { quotaId },
  });
};

/**********************
 * DOCUMENTACION
 *********************/

export const getSignDocumentationInfo = async (processId: number | string) => {
  const url = TUITION_CONTINUITY.SIGN_DOCUMENTATION_STEP(processId);
  return tcRequest<{
    documents: { type: string; required: boolean; isUploaded: boolean }[];
  }>(url);
};

export const getSignDocumentationDocumentGenerated = async (
  processId: number | string,
) => {
  const url = TUITION_CONTINUITY.SIGN_DOCUMENTATION_DOCUMENT_GENERATED(
    processId,
  );
  return tcRequest<{ url: string }>(url);
};

/**
 * Toma un nombre de documento y un objeto de valores, y devuelve una promesa que se resuelve en la
 * respuesta de una solicitud POST al servidor.
 * @param {GeneralTypes} documentName - Tipos de documentos del paso de documentación,
 * @param {FilesUpload} values - compuesto de names y data (datos del formulario)
 * @returns El regreso es una promesa.
 */
export const uploadSignDocumentation = (
  documentName: GeneralTypes,
  values: FilesUpload,
  tuitionContinuityProcessId: number,
) => {
  const url = TUITION_CONTINUITY.SIGN_DOCUMENTATION_DOCUMENT_UPLOAD(
    tuitionContinuityProcessId,
  );

  const formData = new FormData();
  formData.append('type', documentName);

  Object.entries(values).forEach(([key, value]) => {
    if (value instanceof File) formData.append(key, value, value.name);
    else formData.append(key, value);
  });

  return tcRequest(url, {
    method: 'post',
    data: formData,
  });
};

export const saveSignDocumentationInfo = (processId: number | string) => {
  const url = TUITION_CONTINUITY.SIGN_DOCUMENTATION_STEP_SAVE(processId);
  return tcRequest<any>(url, {
    method: 'POST',
  });
};

/**********************
 * FICHA DEL SUSTENTADOR
 *********************/
export const getSustainer = (studyPlanEnrollmentId: number | string) =>
  tcRequest<{ data: SustainerStepResponse }>(
    TUITION_CONTINUITY.RECOVER_SUSTAINER(studyPlanEnrollmentId),
    { method: 'get' },
  );

export const saveSustainer = (
  studyPlanEnrollmentId: string | number,
  data: SustainerBody,
) =>
  tcRequest<any>(TUITION_CONTINUITY.SAVE_SUSTAINER(studyPlanEnrollmentId), {
    method: 'post',
    data,
  });
