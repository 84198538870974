interface Account {
  id: number;
  email: string;
  fullname: string;
  isActive: boolean;
  studentId?: number | null;
  teacherId?: number | null;
}

export interface Permission {
  id: number;
  name: string;
  key: PermissionName;
}

export interface UserData extends Account {
  permissions?: Permission['key'][];
}

export enum PermissionName {
  // PERMISOS DE GRADUACIONES DE TITULOS INTERMEDIOS
  INTERIM_DEGREE_GRADUATION_PROCESS_ALL = 'student.interim-degree-graduation-process.all',
}

export enum UserSessionEvent {
  EXPIRED = 'user-session-expired',
}
