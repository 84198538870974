import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { getTariffInfo } from '../../../../api/requests/tuitionContinuity';
import SectionTitle from '../../../../components/text/SectionTitle';
import { useStepState } from '../../../../hooks/useStepState';
import GoBackButton from '../../parts/GoBackButton';
import { TuitionContinuityStepLoader } from '../../parts/TuitionContinuityStepLoader';
import Tariff from './parts/Tariff';

export default function TariffStep() {
  const { prevStep } = useStepState();

  const prefix = 'tuitionContinuity.tariffQuotas';
  const { t } = useTranslation();

  const { studyPlanEnrollmentId: studyPlanEnrollmentIdRaw } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const studyPlanEnrollmentId = parseInt(studyPlanEnrollmentIdRaw);

  const request = useCallback(async () => {
    const response = await getTariffInfo(studyPlanEnrollmentId);
    if (response.data) {
      response.data.quotas.sort((a, b): number => a.quota - b.quota);
    }
    return response;
  }, [studyPlanEnrollmentId]);

  return (
    <>
      <Row className="pb-3 pb-lg-4">
        <Col xs={12} lg={6} className="pb-3">
          <GoBackButton
            onClick={() => prevStep()}
            text={t(`common.actions.goBackStep`)}
          />
        </Col>
      </Row>
      <SectionTitle text={t(`${prefix}.displayData.followIntructionsTitle`)} />
      <p className="fs-16">
        {t(`${prefix}.displayData.followIntructionsDescription`)}
      </p>
      <TuitionContinuityStepLoader request={request}>
        {(data) => (
          <Tariff studyPlanEnrollmentId={studyPlanEnrollmentId} {...data} />
        )}
      </TuitionContinuityStepLoader>
    </>
  );
}
