import { ReactNode } from 'react';

import BaseLoader from './BaseLoader';
import { BaseProvider } from './BaseProvider';

export default function Base({ children }: { children: ReactNode }) {
  return (
    <BaseLoader>
      <BaseProvider children={children} />
    </BaseLoader>
  );
}
