import { DisplayInfo, Icon, Table, TablePagination } from '@octano/global-ui';
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import { useFetch } from '../../../components/contexts/FetchContext';
import DisplayError from '../../../components/info/DisplayError';
import { usePagination } from '../../../hooks/usePagination';
import {
  PaginationQuery,
  PaginationRequestType,
} from '../../../types/paginationRequestType';
import { PetitionInf } from '../../../types/petitions';
import PetitionStatusBadge from './PetitionStatusBadge';
import ViewPetitionModal from './ViewPetitionModal';

export default function PetitionTable() {
  const { data, error, query, updateQuery, loading } = useFetch<
    PaginationQuery,
    PaginationRequestType<PetitionInf>,
    { code: string }
  >();
  const {
    isPaginated,
    totalPages,
    itemsPerPage,
    items,
    currentPage,
    onChangePage,
    total,
  } = usePagination<PetitionInf, PaginationQuery>({
    query,
    items: data,
    search: updateQuery,
    itemsPerPage: 10,
    page: 0,
  });
  const { t } = useTranslation();
  const prefix = 'petitions.table';
  const text = useMemo(
    () => ({
      empty: {
        title: t(`${prefix}.empty.title`),
        body: t(`${prefix}.empty.subtitle`),
      },
      columns: {
        folio: t(`${prefix}.columns.folio`),
        createdAt: t(`${prefix}.columns.createdAt`),
        studyPlanVersionName: t(`${prefix}.columns.studyPlanVersionName`),
        typeName: t(`${prefix}.columns.typeName`),
        title: t(`${prefix}.columns.title`),
        status: t(`${prefix}.columns.status`),
        view: t(`${prefix}.columns.view`),
      },
      error: {
        title: t(`${prefix}.error.title`),
        body: t(`${prefix}.error.body`),
      },
    }),
    [t],
  );
  const [selectedPetitionId, setPetition] = useState<number | undefined>();

  const toggle = useCallback((petition?: PetitionInf) => {
    if (petition && 'folio' in petition) {
      setPetition(petition.folio);
    } else {
      setPetition(undefined);
    }
  }, []);

  const columns = useMemo<ColumnTable<PetitionInf>[]>(
    () => [
      {
        columnName: 'folio',
        width: '15%',
        headerText: text.columns.folio,
        thClassName: 'text-center',
        tdClassName: 'text-center',
      },
      {
        columnName: 'createdAt',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: text.columns.createdAt,
        cellFormat: (options) => dayjs(options.value).format('DD/MM/YYYY'),
      },
      {
        columnName: 'studyPlanVersionName',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: text.columns.studyPlanVersionName,
      },
      {
        columnName: 'typeName',
        width: '15%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: text.columns.typeName,
      },
      {
        columnName: 'title',
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: text.columns.title,
      },
      {
        columnName: 'status',
        width: '130px',
        headerText: text.columns.status,
        thClassName: 'text-center',
        tdClassName: 'text-center',
        cellFormat: ({ row, value }) => {
          return <PetitionStatusBadge status={value} name={row.folio} />;
        },
      },
      {
        thClassName: 'text-center',
        tdClassName: 'text-center',
        columnName: 'view',
        width: '6%',
        headerText: text.columns.view,
        cellFormat: ({ row }) => {
          return (
            <span onClick={() => toggle(row)} className="cursor-pointer">
              <Icon name="eye" size="25px" />
            </span>
          );
        },
      },
    ],
    [text, toggle],
  );
  if (error) {
    return (
      <DisplayError
        insideCard
        loadingAction={loading}
        title={text.error.title}
        body={text.error.body}
        retryAction={updateQuery}
      />
    );
  }
  return (
    <Card className="mt-4" style={{ minHeight: 333 }}>
      <Table
        columns={columns}
        data={items}
        noResultsText={
          <DisplayInfo
            insideCard
            maxWidth="400px"
            textBody={text.empty.body}
            title={text.empty.title}
          />
        }
        isLoadingResults={loading}
      />
      {isPaginated && (
        <TablePagination
          pagination={{
            currentPage,
            itemsPerPage,
            onChangePage,
            totalItems: total,
            totalPages,
          }}
        />
      )}
      <ViewPetitionModal petitionId={selectedPetitionId} onClose={toggle} />
    </Card>
  );
}
