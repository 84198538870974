import { useState } from 'react';
import { Col, Row } from 'reactstrap';

import SelectedSections from '../../../components/courseEnrollment/SelectedSections';
import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';
import Calendar from '../../coursesSearching/parts/Calendar';
import ActionButtons from './ActionButtons';
import FilteredTable from './FilteredTable';
import SearchForm from './SearchForm';

const SearchingProcess = () => {
  const { selectedSections, removeSection } = useCourseSelectionState();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableError, setTableError] = useState<boolean>(false);
  const [currentSemester, setCurrentSemester] = useState<string | undefined>(
    undefined,
  );
  const [currentTeacher, setCurrentTeacher] = useState<string | undefined>(
    undefined,
  );

  return (
    <>
      <Row>
        <Col>
          <SearchForm
            setTableLoading={setTableLoading}
            setTableError={setTableError}
            setCurrentSemester={setCurrentSemester}
            setCurrentTeacher={setCurrentTeacher}
          />
        </Col>
      </Row>
      <Row className="py-4">
        <Col xs={12} lg={5} className="mb-4">
          <FilteredTable
            tableLoading={tableLoading}
            setTableLoading={setTableLoading}
            tableError={tableError}
            setTableError={setTableError}
            currentSemester={currentSemester}
            currentTeacher={currentTeacher}
          />
        </Col>
        <Col xs={12} lg={7}>
          <Calendar />
          <SelectedSections
            selectedSections={selectedSections}
            removeSection={removeSection}
          />
          {selectedSections.length > 0 && <ActionButtons />}
        </Col>
      </Row>
    </>
  );
};

export default SearchingProcess;
