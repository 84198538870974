import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface ModalConfirmRegistrationProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  onPressAccept: () => void;
  isLoadingAction: boolean;
}

/**
 * Modal para confirmar la inscripción de asignaturas
 */
const ModalConfirmRegistration = ({
  isModalOpen,
  toggleModal,
  onPressAccept,
  isLoadingAction,
}: ModalConfirmRegistrationProps) => {
  const prefix = 'finishCourseRegistration';
  const { t } = useTranslation();

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <div className="text-center">
        <Row>
          <Col xs={12}>
            <Icon name="information" size="50px" />
          </Col>
          <Col xs={12} className="pt-4">
            <span className="text-dark fs-22">
              {t(`${prefix}.modalConfirmation.title`)}
            </span>
          </Col>
          <Col xs={12} className="pt-3 pb-4">
            <span className="fs-18 text-light">
              {t(`${prefix}.modalConfirmation.body`)}
            </span>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ size: 12, order: 2 }}
            md={{ size: 6, order: 1 }}
            className="py-1 px-2"
          >
            <Button
              outlined
              fullwidth
              onClick={toggleModal}
              text={t(`common.btnCancel`)}
              disabled={isLoadingAction}
            />
          </Col>
          <Col
            xs={{ size: 12, order: 1 }}
            md={{ size: 6, order: 2 }}
            className="py-1 px-2"
          >
            <Button
              fullwidth
              onClick={onPressAccept}
              text={t(`common.btnFinish`)}
              loading={isLoadingAction}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalConfirmRegistration;
