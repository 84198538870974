import {
  Button,
  Modal,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SelectOptionType,
} from '@octano/global-ui';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { useBase } from '../../../components/base/BaseProvider';
import { Period } from '../../../components/careerSelector/academicInformationTypes';

const format = ({
  id: value,
  name: label,
}: Period): OutlinedSelectOptionType => ({
  value,
  label,
});

interface ValuesConfirm {
  periodId?: string | number;
}

interface FieldsForm {
  selectedPeriod?: SelectOptionType | null;
}

interface Props {
  texts: { title: string; description: string };
  isOpen: boolean;
  onConfirm?: (values: ValuesConfirm) => void;
  onClose: () => void;
}

export default function SelectPeriodModal({
  texts = { title: '', description: '' },
  isOpen,
  onConfirm = (values: ValuesConfirm) => console.log(values),
  onClose,
}: Props) {
  const { t } = useTranslation();

  const { allPeriods } = useBase();
  const options = useMemo(() => allPeriods?.map(format) ?? [], [allPeriods]);

  const methods = useForm<FieldsForm>({
    mode: 'onSubmit',
  });

  const {
    reset,
    formState: { isSubmitting },
    handleSubmit,
    control,
  } = methods;

  const onSubmit = (values: FieldsForm) => {
    onConfirm({
      periodId: values.selectedPeriod?.value,
    });
  };

  useEffect(() => reset(), [isOpen, reset]);

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <Row className="text-center">
        <Col xs={12} className="pb-3">
          <h2 className="fs-22 text-dark">{texts.title}</h2>
        </Col>
        <Col xs={12}>
          <p className="fs-16 fw-300">{texts.description}</p>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-5">
          <Col xs={12}>
            <OutlinedSelect
              name="selectedPeriod"
              label={t(`reports.enrolledStudent.selectPeriod.form.period`)}
              control={control}
              options={options}
              rules={{
                required: true,
              }}
              isClearable={false}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="pb-2">
            <Button
              onClick={onClose}
              text={t('reports.enrolledStudent.selectPeriod.buttons.cancel')}
              outlined
              fullwidth
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} sm={6} className="pb-2">
            <Button
              type="submit"
              text={t('reports.enrolledStudent.selectPeriod.buttons.download')}
              fullwidth
              loading={isSubmitting}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
