import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import Base from '../../components/base/Base';
import CareerSelector from '../../components/careerSelector/CareerSelector';
import { CareerStatus } from '../../components/careerSelector/academicInformationTypes';
import TopBar from '../../components/topBar/TopBar';
import CoursesLoader from './parts/CourseLoader';
import CoursesList from './parts/CoursesList';
import StudentStatusLoader from './parts/StudentStatusLoader';

export default function MyCourses() {
  const { t } = useTranslation();

  return (
    <Base>
      <TopBar title={t(`myCourses.topBarTitle`)} />
      <Card className="p-4 mx-3">
        <CareerSelector allowedStatus={[CareerStatus.Regular]}>
          <StudentStatusLoader>
            {(statusToEnroll) => (
              <CoursesLoader>
                {(courses) => (
                  <CoursesList
                    courses={courses}
                    errorLoading={false}
                    statusToEnroll={statusToEnroll}
                  />
                )}
              </CoursesLoader>
            )}
          </StudentStatusLoader>
        </CareerSelector>
      </Card>
    </Base>
  );
}
