import { DetailBox, DetailBoxColumn } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Career, Period } from '../academicInformationTypes';

export interface Props {
  career: Career;
  period: Period | null;
}

export default function StudyPlanBox({ career, period }: Props) {
  const { t } = useTranslation();

  const columns = useMemo<DetailBoxColumn[]>(() => {
    const { name, mentions, campus, schedule } = career;
    const cols = [];

    cols.push({
      title: t(`myCourses.studyPlanInfo.name`),
      body: name,
      size: { xs: 6, sm: 'auto' },
    });

    if (mentions.length > 0) {
      cols.push({
        title: t(`myCourses.studyPlanInfo.mention`),
        body: mentions[0].name,
        size: { xs: 6, sm: 'auto' },
      });
    }

    if (schedule) {
      cols.push({
        title: t(`myCourses.studyPlanInfo.schedule`),
        body: schedule.name,
        size: { xs: 6, sm: 'auto' },
      });
    }

    cols.push({
      title: period?.name
        ? period?.name?.split(' ')[0]
        : t(`myCourses.studyPlanInfo.semester`),
      body: period?.name ?? '-',
      size: { xs: 6, sm: 'auto' },
    });

    if (campus) {
      cols.push({
        title: t(`myCourses.studyPlanInfo.campus`),
        body: campus.name,
        size: { xs: 6, sm: 'auto' },
      });
    }

    return cols;
  }, [career, period, t]);

  return <DetailBox columns={columns} />;
}
