import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosResultDefaultError } from '../../../api/request';
import { requestStudentSectionsInfo } from '../../../api/requests/studentManagement';
import { CareerStatus } from '../../../components/careerSelector/academicInformationTypes';
import { useAcademicInformation } from '../../../components/careerSelector/useAcademicInformation';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { StudentSelectedSections } from '../../../types/studentManagementTypes';
import { Props as CoursesListProps } from '../parts/CoursesList';

export interface CoursesLoaderProps {
  children: (courses: CoursesListProps['courses']) => ReactElement;
}

/**
 * El tipado original del course trae un array de "types"
 */
type SelectedSection = StudentSelectedSections[0];
type SingleCourseType = SelectedSection['course']['types'][0];
type SectionCourseWithoutTypes = Omit<SelectedSection['course'], 'types'>;
type SectionResponse = SelectedSection & {
  course: SectionCourseWithoutTypes & { types: SingleCourseType };
};

export default function CoursesLoader({ children }: CoursesLoaderProps) {
  const { t } = useTranslation();
  const { loading, setLoading } = useLoadingState();
  const [courses, setCourses] = useState<CoursesListProps['courses']>([]);

  const { selectedCareer, selectedPeriod } = useAcademicInformation();
  const [error, setError] = useState<AxiosResultDefaultError>();

  const load = useCallback(async () => {
    setLoading(true);

    if (!selectedPeriod) {
      setLoading(false);
      return;
    }

    const sectionsResponse = await requestStudentSectionsInfo(
      selectedCareer.studyPlanEnrollmentId,
    );

    if (sectionsResponse.data) {
      const sections = sectionsResponse.data;
      const auxCourses = (sections as SectionResponse[]).map((section) => ({
        id: section.sectionData.id,
        shortening: section.course.shortening,
        name: section.course.name,
        section: section.sectionData.name,
        credits: section.course.credits,
        type: section.course.types.name,
        professors: section.sectionData.professors,
      }));
      setCourses(auxCourses);
      setError(undefined);
    }
    if (sectionsResponse.error) {
      setError(sectionsResponse.error);
    }

    setLoading(false);
  }, [selectedCareer.studyPlanEnrollmentId, selectedPeriod, setLoading]);

  useEffect(() => {
    if (selectedCareer.status === 'regular') {
      load();
    }
  }, [selectedCareer.status, load]);

  if (selectedCareer.status !== CareerStatus.Regular) {
    return (
      <DisplayError
        insideCard
        title={t('common.careerSelector.error.title')}
        body={t('common.careerSelector.error.body')}
        loadingAction={loading}
      />
    );
  }

  if (error) {
    return (
      <DisplayError
        insideCard
        body={error.code}
        retryAction={load}
        loadingAction={loading}
      />
    );
  }

  if (loading) {
    return <Loading insideCard />;
  }

  if (!courses) {
    return (
      <DisplayError
        insideCard
        body="Data no cargada"
        retryAction={load}
        loadingAction={loading}
      />
    );
  }

  return children(courses);
}
