import { ToastProvider } from '@octano/global-ui';
import { useMemo } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import AppVersion from './components/version/AppVersion';
import routes from './config/routes';
import { useUserState } from './hooks/useUserState';
import CourseSelectionProvider from './providers/CourseSelectionProvider';
import ParametersProvider from './providers/ParametersProvider';
import UserProvider from './providers/UserProvider';
import withProvider from './providers/withProvider';

function App() {
  const { userData, isAuthorizedTo } = useUserState();

  const routeEntries = useMemo(() => {
    const routeEntriesArr = Object.entries(routes);
    return routeEntriesArr?.filter(([_, value]) => {
      if (value.disabled) {
        return false;
      }
      if (!value.requiredPermissions?.length) {
        /**
         * Permite otorgar permiso por ruta
         */
        const specificPermissionPerPath = value?.views.flatMap(
          (v) => v?.requiredPermissions || [],
        );
        if (specificPermissionPerPath.length === 0) {
          return true;
        }
        return isAuthorizedTo(
          specificPermissionPerPath,
          value.allPermissionsRequired,
        );
      }
      return isAuthorizedTo(
        value?.requiredPermissions || [],
        value.allPermissionsRequired,
      );
    });
    // eslint-disable-next-line
  }, [userData, isAuthorizedTo]);

  return (
    <ToastProvider>
      <CourseSelectionProvider>
        <ParametersProvider>
          <AppVersion />
          <Router>
            <Switch>
              {routeEntries?.map(([key, value]) => {
                const Layout = value.layoutComponent;
                return (
                  <Route
                    key={key}
                    path={key}
                    render={(props: any) => (
                      <Layout {...props} pathname={key} />
                    )}
                  />
                );
              })}
              <Redirect to={routeEntries[0][0]} />
            </Switch>
          </Router>
        </ParametersProvider>
      </CourseSelectionProvider>
    </ToastProvider>
  );
}

export default withProvider(App, UserProvider);
