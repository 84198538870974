import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type ButtonBackProps = {
  onClick?: () => void;
  text?: string;
};

const ButtonBack = ({ onClick, text }: ButtonBackProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      type="button"
      text={text ? text : t('common.btnBack')}
      icon="back"
      size="sm"
      outlined
      rounded
      color="secondary"
      onClick={() => (onClick ? onClick() : history.goBack())}
    />
  );
};

export default ButtonBack;
