import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
} from '@octano/global-ui';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import {
  requestCampus,
  requestFaculties,
  requestModality,
  requestStudyPlansFilter,
  requestTeachers,
} from './../../../api/requests/courseSearching';
import { useSearchingCoursesState } from './../../../hooks/useSearchingCoursesState';
import {
  CampusType,
  FacultyType,
  ModalityType,
  SemesterType,
  StudyPlanType,
  TeacherType,
} from './../../../types/courseSearchingTypes';

type FilterFields = {
  faculty?: OutlinedSelectOptionType | null;
  campus?: OutlinedSelectOptionType | null;
  studyPlan?: OutlinedSelectOptionType | null;
  teacher?: OutlinedSelectOptionType | null;
  semester?: OutlinedSelectOptionType | null;
  modality?: OutlinedSelectOptionType | null;
};

type SearchFormProps = {
  setTableLoading: Dispatch<SetStateAction<boolean>>;
  setTableError: Dispatch<SetStateAction<boolean>>;
  setCurrentSemester: Dispatch<SetStateAction<string | undefined>>;
  setCurrentTeacher: Dispatch<SetStateAction<string | undefined>>;
};

const SEMESTERS = [
  { id: 1, name: 'Periodo 1' },
  { id: 2, name: 'Periodo 2' },
  { id: 3, name: 'Periodo 3' },
  { id: 4, name: 'Periodo 4' },
  { id: 5, name: 'Periodo 5' },
  { id: 6, name: 'Periodo 6' },
  { id: 7, name: 'Periodo 7' },
  { id: 8, name: 'Periodo 8' },
  { id: 9, name: 'Periodo 9' },
  { id: 10, name: 'Periodo 10' },
  { id: 11, name: 'Periodo 11' },
  { id: 12, name: 'Periodo 12' },
  { id: 13, name: 'Periodo 13' },
  { id: 14, name: 'Periodo 14' },
  { id: 15, name: 'Periodo 15' },
  { id: 16, name: 'Periodo 16' },
  { id: 17, name: 'Periodo 17' },
  { id: 18, name: 'Periodo 18' },
  { id: 19, name: 'Periodo 19' },
  { id: 20, name: 'Periodo 20' },
];

const SearchForm = ({
  setTableLoading,
  setTableError,
  setCurrentSemester,
  setCurrentTeacher,
}: SearchFormProps) => {
  const prefix = 'courseSearching.form';
  const { t } = useTranslation();

  const [optionsFaculty, setOptionsFaculty] = useState<
    OutlinedSelectOptionType[]
  >([]);
  const [optionsStudyPlans, setOptionsStudyPlans] = useState<
    OutlinedSelectOptionType[]
  >([]);
  const [optionsCampus, setOptionsCampus] = useState<
    OutlinedSelectOptionType[]
  >([]);
  const [optionsTeachers, setOptionsTeachers] = useState<
    OutlinedSelectOptionType[]
  >([]);
  const [optionsSemesters, setOptionsSemesters] = useState<
    OutlinedSelectOptionType[]
  >([]);
  const [optionsModality, setOptionsModality] = useState<
    OutlinedSelectOptionType[]
  >([]);
  const { setStudyPlans, setCourses, setSections } = useSearchingCoursesState();

  const { control, handleSubmit, watch, reset } = useForm<FilterFields>({
    mode: 'onSubmit',
    defaultValues: {
      faculty: null,
      campus: null,
      studyPlan: null,
      teacher: null,
      semester: null,
      modality: null,
    },
  });

  const [faculty, campus, studyPlan, teacher, semester, modality] = watch([
    'faculty',
    'campus',
    'studyPlan',
    'teacher',
    'semester',
    'modality',
  ]);

  const getFaculties = async () => {
    const { data, error } = await requestFaculties();
    if (data) {
      setOptionsFaculty(
        data.map((element: FacultyType) => ({
          key: element.id,
          value: element.id,
          label: element.name,
        })),
      );
    } else if (error) {
      setOptionsFaculty([]);
      // TODO: que mensaje se desplegara al existir un error?
    }
  };

  const getStudyPlans = async () => {
    const { data, error } = await requestStudyPlansFilter();
    if (data) {
      setOptionsStudyPlans(
        data.map((element: StudyPlanType) => ({
          key: element.id,
          value: element.id,
          label: element.name,
        })),
      );
    } else if (error) {
      setOptionsStudyPlans(
        [].map((element: StudyPlanType) => ({
          key: element.id,
          value: element.id,
          label: element.name,
        })),
      );
      // TODO: que mensaje se desplegara al existir un error?
    }
  };

  const getCampus = async () => {
    const { data, error } = await requestCampus();
    if (data) {
      setOptionsCampus(
        data.map((element: CampusType) => ({
          value: element.id,
          label: element.name,
        })),
      );
    } else if (error) {
      setOptionsCampus([]);
      // TODO: que mensaje se desplegara al existir un error?
    }
  };

  const getTeachers = async () => {
    const { data, error } = await requestTeachers();
    if (data && data.data) {
      setOptionsTeachers(
        data.data.map((element: TeacherType) => ({
          key: element.teacher.id,
          value: element.teacher.id,
          label: element.fullName,
        })),
      );
    } else if (error) {
      setOptionsModality([]);
      // TODO: que mensaje se desplegara al existir un error?
    }
  };

  const getSemesters = async () => {
    setOptionsSemesters(
      SEMESTERS.map((element: SemesterType) => ({
        key: element.id,
        value: element.id,
        label: element.name,
      })),
    );
  };

  const getModality = async () => {
    const { data, error } = await requestModality();
    if (data) {
      setOptionsModality(
        data.map((element: ModalityType) => ({
          key: element.id,
          value: element.id,
          label: element.name,
        })),
      );
    } else if (error) {
      setOptionsModality([]);
      // TODO: que mensaje se desplegara al existir un error?
    }
  };

  const parseStudyPlanData = (plans: any[]) => {
    const result: any = [];
    const ACTIVO = 'activo';
    const VIGENTE = 'vigente';
    plans.forEach((plan) => {
      if (plan.versions && plan.versions.length > 0) {
        plan.versions.forEach((version: any) => {
          if (version.curriculums && version.curriculums.length > 0) {
            version.curriculums.forEach((curriculum: any) => {
              if (
                curriculum.status.toLowerCase() === ACTIVO ||
                curriculum.status.toLowerCase() === VIGENTE
              ) {
                result.push({
                  id: plan.id,
                  code: plan.code,
                  name: plan.name,
                  versionId: version.id,
                  versionName: version.name,
                  versionCode: version.code,
                  versionStatus: version.status,
                  curriculumId: curriculum.id,
                  curriculumName: curriculum.name,
                  curriculumCode: curriculum.code,
                  curriculumStatus: curriculum.status,
                  curriculumIsPrimary: curriculum.isPrimary,
                });
              }
            });
          }
        });
      }
    });
    result.forEach((value: any, index: number) => {
      if (
        value.curriculumIsPrimary &&
        result.find((element: any) => element.id === value.id).length === 1
      ) {
        result[index].showPlanNameOnly = true;
      }
    });
    return result;
  };

  const searchSrudyPlansByFilters = useCallback(async () => {
    setSections(undefined);
    setCourses(undefined);
    setTableLoading(true);
    setCurrentSemester(semester?.label.toString());
    setCurrentTeacher(teacher?.value.toString());
    const { data, error } = await requestStudyPlansFilter(
      faculty?.value.toString(),
      campus?.value.toString(),
      studyPlan?.value.toString(),
      teacher?.value.toString(),
      semester?.label.toString(),
      modality?.value.toString(),
    );

    if (data) {
      setTableError(false);
      const formattedData = parseStudyPlanData(data);
      setStudyPlans(formattedData);
      setTableLoading(false);
    } else if (error) {
      setTableLoading(false);
      setStudyPlans([]);
      setTableError(true);
      // TODO: que mensaje se desplegara al existir un error?
    }
  }, [
    faculty,
    campus,
    studyPlan,
    teacher,
    semester,
    modality,
    setCourses,
    setCurrentSemester,
    setCurrentTeacher,
    setSections,
    setStudyPlans,
    setTableError,
    setTableLoading,
  ]);

  const cleanFilters = () => {
    reset();
    setSections(undefined);
    setCourses(undefined);
    setStudyPlans(undefined);
  };

  useEffect(() => {
    getFaculties();
    getStudyPlans();
    getCampus();
    getTeachers();
    getSemesters();
    getModality();
  }, []);

  useEffect(() => {
    if (faculty || campus || studyPlan || teacher || semester || modality) {
      searchSrudyPlansByFilters();
    }
  }, [
    faculty,
    campus,
    studyPlan,
    teacher,
    semester,
    modality,
    searchSrudyPlansByFilters,
  ]);

  return (
    <div className="search-form">
      <Form onSubmit={handleSubmit(searchSrudyPlansByFilters)}>
        <Row>
          <Col xs={12} md={3}>
            <label>{t(`${prefix}.faculty`)}</label>
            <OutlinedSelect
              name="faculty"
              isClearable={false}
              control={control}
              options={optionsFaculty}
              placeholder={t(`${prefix}.searchByFaculty`)}
            />
          </Col>
          <Col xs={12} md={3}>
            <label>{t(`${prefix}.studyPlan`)}</label>
            <OutlinedSelect
              name="studyPlan"
              isClearable={false}
              control={control}
              options={optionsStudyPlans}
              placeholder={t(`${prefix}.searchByStudyPlan`)}
            />
          </Col>
          <Col xs={12} md={3}>
            <label>{t(`${prefix}.campus`)}</label>
            <OutlinedSelect
              name="campus"
              isClearable={false}
              control={control}
              options={optionsCampus}
              placeholder={t(`${prefix}.searchByCampus`)}
            />
          </Col>
          <Col xs={12} md={3}>
            <label>{t(`${prefix}.modality`)}</label>
            <OutlinedSelect
              name="modality"
              isClearable={false}
              control={control}
              options={optionsModality}
              placeholder={t(`${prefix}.searchByModality`)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <label>{t(`${prefix}.teacher`)}</label>
            <OutlinedSelect
              name="teacher"
              isClearable={false}
              control={control}
              options={optionsTeachers}
              placeholder={t(`${prefix}.searchByTeacher`)}
            />
          </Col>
          <Col xs={12} md={3}>
            <label>{t(`${prefix}.semester`)}</label>
            <OutlinedSelect
              name="semester"
              isClearable={false}
              control={control}
              options={optionsSemesters}
              placeholder={t(`${prefix}.searchBySemester`)}
            />
          </Col>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={3}>
            <div className="action-buttons search">
              <Button
                className="clean-button search-button"
                text={t(`${prefix}.search`)}
                size="sm"
                onClick={searchSrudyPlansByFilters}
              />
            </div>
            <div className="action-buttons">
              <Button
                className="clean-button"
                text={t(`${prefix}.clean`)}
                outlined
                size="sm"
                onClick={cleanFilters}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchForm;
