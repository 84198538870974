import { OutlinedSelectOptionType } from '@octano/global-ui';
import { Dispatch } from 'react';

import {
  CourseType,
  StudentInfoType,
} from '../types/courseRegistrationRequestTypes';
import { SectionType } from './sectionType';

export enum CourseSelectionActionTypes {
  RESET_STATE = 'RESET_STATE',
  SET_STUDENT_INFO = 'SET_STUDENT_INFO',
  SET_CAMPUS_CAREER = 'SET_CAMPUS_CAREER',
  SET_COURSE = 'SET_IS_LOGGED',
  ADD_SECTION = 'ADD_SECTION',
  REMOVE_SECTION = 'REMOVE_SECTION',
  REMOVE_ALL_SECTIONS = 'REMOVE_ALL_SECTIONS',
  SELECT_CAMPUS = 'SELECT_CAMPUS',
  SET_CREDITS_PERIOD = 'SET_CREDITS_PERIOD',
  SET_SELECTED_CAREER = 'SET_SELECTED_CAREER',
  ADD_SECTION_ALLOWING_REPEATED = 'ADD_SECTION_ALLOWING_REPEATED',
}

export type CourseSelectionAction =
  | {
      type: CourseSelectionActionTypes.RESET_STATE;
    }
  | {
      type: CourseSelectionActionTypes.SET_STUDENT_INFO;
      payload: StudentInfoType;
    }
  | {
      type: CourseSelectionActionTypes.SET_CAMPUS_CAREER;
      payload: Campus | null;
    }
  | {
      type: CourseSelectionActionTypes.SET_COURSE;
      payload: SelectedCouse | null;
    }
  | {
      type: CourseSelectionActionTypes.ADD_SECTION;
      payload: SelectedSection;
    }
  | {
      type: CourseSelectionActionTypes.REMOVE_SECTION;
      payload: number;
    }
  | {
      type: CourseSelectionActionTypes.SELECT_CAMPUS;
      payload: OutlinedSelectOptionType | undefined;
    }
  | {
      type: CourseSelectionActionTypes.REMOVE_ALL_SECTIONS;
    }
  | {
      type: CourseSelectionActionTypes.SET_CREDITS_PERIOD;
      payload: CreditPeriod;
    }
  | {
      type: CourseSelectionActionTypes.SET_SELECTED_CAREER;
      payload: SelectedCareer | null;
    }
  | {
      type: CourseSelectionActionTypes.ADD_SECTION_ALLOWING_REPEATED;
      payload: SelectedSection;
    };

export type CourseSelectionState = {
  student: null | StudentInfoType;
  campusCareer: Campus | null;
  selectedCampus: OutlinedSelectOptionType | undefined;
  selectedCourse: SelectedCouse | null;
  selectedSections: SelectedSection[];
  selectedCredits: number;
  creditsPeriod: CreditPeriod;
  selectedCareer: SelectedCareer | null;
};

export type CourseSelectionContextType = {
  state: CourseSelectionState;
  dispatch: Dispatch<CourseSelectionAction>;
};

export type Campus = { id: number; name: string };

export type SelectedCouse = CourseType;

export interface SelectedSection extends SectionType {
  course: SelectedCouse;
}

export type CreditPeriod = {
  creditMin: number;
  creditMax: number;
};

export type SelectedCareer = {
  studyPlanEnrollmentId: number;
  career: {
    id: number;
    name: string;
  };
  mention?: {
    id: number;
    name: string;
  } | null;
};
