import { useCallback, useContext } from 'react';

import { SearchingCoursesContext } from '../providers/SearchingCoursesProvider';
import { CourseType } from '../types/courseRegistrationRequestTypes';
import {
  SearchingCoursesActionTypes,
  StudyPlanType,
} from '../types/courseSearchingTypes';
import { SectionType } from '../types/sectionType';

/**
 * Provee el estado y el manejo de estado de la selección de cursos
 * que realiza el usuario
 */
export const useSearchingCoursesState = () => {
  const { state, dispatch } = useContext(SearchingCoursesContext);

  const setStudyPlans = useCallback(
    async (studyPlans: StudyPlanType[] | undefined) => {
      dispatch({
        type: SearchingCoursesActionTypes.SET_STUDY_PLANS,
        payload: studyPlans,
      });
    },
    [dispatch],
  );

  const setCourses = useCallback(
    async (courses: CourseType[] | undefined) => {
      dispatch({
        type: SearchingCoursesActionTypes.SET_COURSES,
        payload: courses,
      });
    },
    [dispatch],
  );

  const setSections = useCallback(
    async (sections: SectionType[] | undefined) => {
      dispatch({
        type: SearchingCoursesActionTypes.SET_SECTIONS,
        payload: sections,
      });
    },
    [dispatch],
  );

  const getSegmentedList = (list: any[] | undefined, page = 10) => {
    return list?.slice(undefined, page);
  };

  return {
    ...state,
    setCourses,
    setStudyPlans,
    setSections,
    getSegmentedList,
  };
};
