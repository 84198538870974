import { Trans, useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { ReactComponent as CompletedImage } from '../../../../assets/svg/completed_image.svg';

export default function CompletedStep() {
  const prefix = `tuitionContinuity.steps.completed`;
  const { t } = useTranslation();

  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <Row>
          <Col xs={12}>
            <div
              className="d-flex py-4 flex-column align-items-center m-auto"
              style={{ maxWidth: '694px', gap: 24 }}
            >
              <CompletedImage width="230px" />
              <span className="text-center fs-20 text-dark">
                {t(`${prefix}.${'title'}`)}
              </span>
              <span className="text-center fs-18 fw-300">
                <Trans i18nKey={`${prefix}.${'body'}`} />
              </span>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}
