import { CourseHistory } from '../../types/curricularAdvancementTypes';
import { CURRICULUM } from '../endpoints';
import request from '../request';

export type CurricularProgressionResponse = {
  id: number;
  code: string;
  name: string;
  isPrimary: boolean;
  duration: number;
  activityNumber: number;
  obligatoryCredits: number;
  optionalCredits: number;
  otherCredits: number;
  totalCredits: number;
  status: string;
  semesters: Semester[];
  studyPlanVersion: StudyPlanVersion;
};

export type StudyPlanVersion = {
  id: number;
  code: string;
  studyPlanId: number;
  name: string;
  status: string;
  degreeRequirements: any;
  entryRequirements: any;
  graduationRequirements: any;
  studyPlan: StudyPlan;
};

export type StudyPlan = {
  periodType: PeriodType;
};

export type PeriodType = {
  name: string;
  namePlural: string;
};

export type Semester = {
  id: number;
  name: string;
  title: string;
  semesterIndex: number;
  semesterCourses: SemesterCourse[];
};

export type SemesterCourse = {
  id: number;
  course?: {
    id: number;
    code: string;
    credits: number;
    name: string;
    shortening: string;
  };
  courseType: {
    id: number;
    name: string;
    priority: number;
  };
  optionalSlot?: {
    id: number;
    name: string;
    credits: number;
  };
  state?: 'A' | 'P' | 'R' | 'C' | 'V';
  isValidated: boolean;
};

/**
 * Obtiene información de la malla del estudiante y el avance que tiene en ésta
 */

export const requestCurriculumProgression = (
  studyPlanEnrollmentId: number | string,
) => {
  return request<{ progress: CurricularProgressionResponse }>(
    CURRICULUM.PROGRESSION(studyPlanEnrollmentId),
    {
      method: 'get',
    },
  );
};

export const getCurriculumProgressRequest = (studyPlanEnrollmentId: number) => {
  const url = CURRICULUM.PROGRESS(studyPlanEnrollmentId);
  return request<any>(url, {
    method: 'get',
  });
};

export const getCourseHistoryRequest = (studyPlanEnrollmentId: number) => {
  const url = CURRICULUM.COURSE_HISTORY(studyPlanEnrollmentId);
  return request<{ data: CourseHistory }>(url, {
    method: 'get',
  });
};

export const getCourseHistoryPdf = (studyPlanEnrollmentId: number) => {
  const url = CURRICULUM.COURSE_HISTORY_DOWNLOAD(studyPlanEnrollmentId);
  return request<any>(url, {
    method: 'GET',
    responseType: 'blob',
  });
};
