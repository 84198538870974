import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { ReactElement, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

const DEFAULT_VALUES = {
  interimDegreeId: null,
};

interface FormValues {
  interimDegreeId: OutlinedSelectOptionType | null;
}

interface SearchControlsProps {
  dictPrefix: string;
  filters: {
    interimDegrees: { value: number; label: string }[];
  };
  children: (props: { data: any }) => ReactElement;
}

export function SearchControls({
  dictPrefix,
  filters,
  children,
}: SearchControlsProps) {
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      selectInterimDegreeLabel: t(`${dictPrefix}.searchControls.interimDegree`),
      selectInterimDegreePlaceholder: t(
        `${dictPrefix}.searchControls.interimDegreeIdDefaultValue`,
      ),
      btnSearch: t(`common.actions.search`),
      btnClean: t(`common.actions.clean`),
    }),
    [t, dictPrefix],
  );

  const isMobile = useMobile();
  const [query, setQuery] = useState<{
    interimDegreeId?: number | string;
    hasFilters: boolean;
  }>({
    interimDegreeId: undefined,
    hasFilters: false,
  });

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const handleSearch = (values: FormValues) => {
    const searchParams = {
      interimDegreeId: values.interimDegreeId?.value as number,
      hasFilters: Object.values(values).some((value) => value !== null),
    };

    setQuery({
      ...searchParams,
    });
  };

  const handleClear = () => {
    reset();
    setQuery({ interimDegreeId: undefined, hasFilters: false });
  };

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      style={{ gap: isMobile ? 0 : 4 }}
    >
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="interimDegreeId"
            label={texts.selectInterimDegreeLabel}
            placeholder={texts.selectInterimDegreePlaceholder}
            control={control}
            options={filters.interimDegrees}
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3, offset: 6 }}
          lg={{ size: 2, offset: 4 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button type="submit" text={texts.btnSearch} size="md" fullwidth />
        </Col>
        <Col
          xs={12}
          md={{ size: 3 }}
          lg={2}
          style={{ marginTop: isMobile ? '1rem' : '1.5rem' }}
        >
          <Button
            text={texts.btnClean}
            size="md"
            fullwidth
            outlined
            onClick={handleClear}
          />
        </Col>
      </Row>
      {children({ data: { ...query } })}
    </form>
  );
}
