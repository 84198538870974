import { ReactElement, useCallback } from 'react';

import { requestStudentTuitionContinuityStatus } from '../../../api/requests/tuitionContinuity';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { StudentTuitionContinuityStatusResponse } from '../../../types/tuitionContinuity';

export interface TuitionContinuityLoaderProps<T> {
  studyPlanEnrollmentId: number;
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  StudentTuitionContinuityStatusResponse
>();

export const TuitionContinuityLoaderConsumer = FetchConsumer;

export default function TuitionContinuityLoader({
  studyPlanEnrollmentId,
  children,
}: TuitionContinuityLoaderProps<StudentTuitionContinuityStatusResponse>) {
  const request = useCallback(() => {
    return requestStudentTuitionContinuityStatus(studyPlanEnrollmentId);
  }, [studyPlanEnrollmentId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                body={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                body="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
