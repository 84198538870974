import { TextInput, Icon } from '@octano/global-ui';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export interface ActivitiesResumenFormProps {
  distinctionRank?: string;
  grade?: string;
  date?: string;
  interimDegreeAverage?: number | string;
}

const dictPrefix = 'interimDegreeActivitiesDetails.form';

export default function ActivitiesResumenForm({
  date,
  distinctionRank = 'N/D',
  grade = 'N/D',
  interimDegreeAverage = 'N/D',
}: ActivitiesResumenFormProps) {
  const { t } = useTranslation();

  return (
    <Col xs={12} className="mt-4">
      <Row>
        <Col className="pb-3" xs={12} lg={6}>
          <TextInput
            name="distinctionRank"
            label={t(`${dictPrefix}.distinctionRank`)}
            value={distinctionRank}
            readOnly
          />
        </Col>
        <Col className="pb-3" xs={12} lg={3}>
          <TextInput
            name="grade"
            label={t(`${dictPrefix}.grade`)}
            value={grade}
            readOnly
          />
        </Col>
        <Col className="pb-3" xs={12} lg={3}>
          <TextInput
            name="interimDegreeAverage"
            label={t(`${dictPrefix}.interimDegreeAverage`)}
            value={interimDegreeAverage}
            readOnly
          />
        </Col>
        {!!date && (
          <Col className="pb-3" xs={12}>
            <p className="text-dark">
              <Icon name="success" color="success" className="mr-2" />
              {t(`${dictPrefix}.degreeDate`, {
                date: dayjs(date).utc().format('DD/MM/YYYY'),
              })}
            </p>
          </Col>
        )}
      </Row>
    </Col>
  );
}
