import {
  Button,
  FileInputControlled,
  Modal,
  TextAreaInput,
} from '@octano/global-ui';
import { Children, cloneElement, useEffect } from 'react';
import { Control, DeepMap, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { FilesUpload } from './signDocumentationTypes';

type IUploadModalProps = {
  isOpen: boolean;
  prefix?: string;
  title?: string;
  subtitle?: string;
  children: JSX.Element[] | JSX.Element;
  onClose: () => void;
  submitFunction?: (values: FilesUpload) => void;
  onSuccess: () => void;
  onError: (errors: DeepMap<FilesUpload, FieldError>) => void;
};

export default function UploadModal({
  isOpen,
  prefix,
  title = 'title',
  subtitle = 'subtitle',
  children,
  onClose,
  submitFunction = (values: FilesUpload) => console.log(values),
  onSuccess,
  onError,
}: IUploadModalProps) {
  const { t } = useTranslation();
  const methods = useForm<FilesUpload>({ mode: 'onSubmit' });
  const {
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
    handleSubmit,
    control,
  } = methods;

  useEffect(() => reset(), [isOpen, reset]);
  useEffect(() => {
    isSubmitSuccessful && onSuccess();
  }, [isSubmitSuccessful, onSuccess]);

  const modalTitle = (prefix && t(`${prefix}.modalTitle`)) || title;
  const modalSubtitle = (prefix && t(`${prefix}.modalSubtitle`)) || subtitle;
  return (
    <Modal isOpen={isOpen} toggle={onClose} closeOnBackdrop={true}>
      <Row className="text-center">
        <Col xs={12} className="pb-3">
          <span className="fs-22 text-dark">{modalTitle}</span>
        </Col>
        <Col xs={12}>
          <p className="fs-16 fw-300">{modalSubtitle}</p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(submitFunction, onError)}>
        <Row className="mb-5">
          {Children.map(children, (child) => {
            if (child.type === UploadInput || child.type === UploadTextArea)
              return cloneElement(child, {
                control,
                isSubmitting,
              });
            return null;
          })}
        </Row>
        <Row>
          <Col xs={12} sm={6} className="pb-2">
            <Button
              onClick={onClose}
              text={t('tuitionContinuity.signDocumentation.buttons.close')}
              outlined
              fullwidth
              disabled={isSubmitting}
            />
          </Col>
          <Col xs={12} sm={6} className="pb-2">
            <Button
              type="submit"
              text={t('tuitionContinuity.signDocumentation.buttons.save')}
              fullwidth
              loading={isSubmitting}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

interface IUploadInputProps {
  control?: Control<FilesUpload>;
  isSubmitting?: boolean;
  prefix?: string;
  name: string;
  label: string;
}

interface IUploadTextAreaProps extends IUploadInputProps {
  height?: number;
}

function UploadInput({
  name,
  label,
  control,
  isSubmitting,
}: IUploadInputProps) {
  const { t } = useTranslation();
  const requiredMsgError = t(
    `tuitionContinuity.signDocumentation.required.image`,
  );
  return (
    <Col xs={12}>
      <FileInputControlled
        name={name}
        label={label}
        rules={{
          required: requiredMsgError,
        }}
        accept="image/png, image/jpeg, application/pdf"
        control={control}
        disabled={isSubmitting}
      />
    </Col>
  );
}

interface IUploadTextAreaProps extends IUploadInputProps {
  height?: number;
}

function UploadTextArea({
  name,
  label,
  control,
  height = 200,
}: IUploadTextAreaProps) {
  const { t } = useTranslation();
  const requiredMsgError = t(
    `tuitionContinuity.signDocumentation.required.textArea`,
  );
  return (
    <Col xs={12}>
      <TextAreaInput
        name={name}
        label={label}
        control={control}
        height={height}
        rules={{
          required: requiredMsgError,
        }}
      />
    </Col>
  );
}

UploadModal.TextArea = UploadTextArea;
UploadModal.Input = UploadInput;
