import { OutlinedSelect, OutlinedSelectOptionType } from '@octano/global-ui';
import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';
import { SectionType } from '../../../types/sectionType';

type SubheaderSectionListProps = {
  sections: SectionType[];
};

/**
 * Subheader del listado de secciones, contiene el nombre de la asignatura seleccionada
 * y un filtro con todos los campus que tienen secciones de la asignatura seleccionada
 */
const SubheaderSectionList = ({ sections }: SubheaderSectionListProps) => {
  const {
    selectedCourse,
    campusCareer,
    selectedCampus,
    setSelectedCampus,
  } = useCourseSelectionState();
  const [optionsCampus, setOptionsCampus] = useState<
    OutlinedSelectOptionType[]
  >([]);

  useEffect(() => {
    setSelectedCampus(undefined);
  }, [setSelectedCampus]);

  useEffect(() => {
    let options: OutlinedSelectOptionType[] = [];
    sections.forEach((section) => {
      if (
        options.length === 0 ||
        !options.some((option) => option.value === section.campus.id)
      ) {
        options.push({ value: section.campus.id, label: section.campus.name });
      }
    });
    setOptionsCampus(options);
  }, [sections, campusCareer]);

  useEffect(() => {
    if (
      optionsCampus.length > 0 &&
      campusCareer &&
      optionsCampus.some((c) => c.value === campusCareer.id)
    ) {
      setSelectedCampus({ value: campusCareer.id, label: campusCareer.name });
    }
  }, [optionsCampus, setSelectedCampus, campusCareer]);

  return (
    <Row className="d-flex justify-content-between mx-0">
      <Col xs={12} md={6} className="pb-2 pb-md-0 pl-0">
        <span className="pl-2 fs-16 text-primary text-uppercase">
          {selectedCourse?.name}
        </span>
      </Col>
      <Col xs={12} md={6} className="fw-400 px-0" style={{ minWidth: '250px' }}>
        <OutlinedSelect
          isClearable={false}
          disabled={sections.length === 0}
          name="filterCampus"
          options={optionsCampus}
          value={selectedCampus}
          onChange={(selected: OutlinedSelectOptionType) => {
            if (selected.value !== selectedCampus?.value) {
              setSelectedCampus(selected);
            }
          }}
          menuPosition="fixed"
          menuShouldBlockScroll={true}
        />
      </Col>
    </Row>
  );
};

export default SubheaderSectionList;
