import { Button, Icon, Modal } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { requestSendRegistration } from '../../../api/requests/courseRegistration';
import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';
import ModalConfirmRegistration from './ModalConfirmRegistration';

enum REGISTRATION_ERROR {
  MIN = 'min',
  MAX = 'max',
  CONNECTION = 'connection',
  QUOTA = 'quota',
}

type EliminatedSection = {
  id: number;
  name: string;
  course: { id: number; code: string; name: string };
};

const FinishRegistration = ({ helpEmail }: { helpEmail: string }) => {
  const prefix = 'finishCourseRegistration';
  const { t } = useTranslation();
  const history = useHistory();
  const {
    removeAllItems,
    creditsPeriod,
    selectedCredits,
    selectedSections,
    resetCourseSelection,
    removeSection,
    setCourseState,
    selectedCareer,
  } = useCourseSelectionState();
  const [errorValidation, setErrorValidation] = useState<
    REGISTRATION_ERROR | undefined
  >();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isFinishing, setIsFinishing] = useState<boolean>(false);
  const [eliminatedSections, setEliminatedSections] = useState<
    EliminatedSection[]
  >([]);

  /**
   * Valida que los créditos seleccionados esten dentro del rango permitido
   */
  const validateCourseRegistration = useCallback((): boolean => {
    if (selectedCredits < creditsPeriod.creditMin) {
      setErrorValidation(REGISTRATION_ERROR.MIN);
      return false;
    } else if (selectedCredits > creditsPeriod.creditMax) {
      setErrorValidation(REGISTRATION_ERROR.MAX);
      return false;
    }
    setErrorValidation(undefined);
    return true;
  }, [setErrorValidation, selectedCredits, creditsPeriod]);

  /**
   * Despliega mensaje de error de secciones sin cupo
   * y las elimina de la selección
   */
  const errorSectionsWithoutQuota = useCallback(
    (sections: EliminatedSection[]) => {
      setErrorValidation(REGISTRATION_ERROR.QUOTA);
      setEliminatedSections(sections);
      sections.forEach((section) => {
        removeSection(section.id);
      });
      setCourseState(null);
    },
    [setErrorValidation, setEliminatedSections, removeSection, setCourseState],
  );

  const submitRegistration = useCallback(async () => {
    setIsFinishing(true);
    try {
      if (validateCourseRegistration() && selectedCareer) {
        const { error } = await requestSendRegistration({
          studyPlanEnrollmentId: selectedCareer.studyPlanEnrollmentId,
          mentionId: selectedCareer.mention?.id || null,
          enrollments: selectedSections.map((section) => {
            return {
              sectionId: section.id,
              semesterCourseId: section.course.semesterCourse!,
            };
          }),
        });

        if (error) {
          // Existen secciones sin cupos que deben ser removidas
          if (error.data.sections) {
            errorSectionsWithoutQuota(error.data.sections);
          } else if (error.data.credits === 'MINIMUM_CREDITS_INSUFFICIENT') {
            // Validaciones de créditos mínimos y máximos
            setErrorValidation(REGISTRATION_ERROR.MIN);
          } else if (error.data.credits === 'MAXIMUM_CREDITS_EXCEEDED') {
            setErrorValidation(REGISTRATION_ERROR.MAX);
          } else {
            setErrorValidation(REGISTRATION_ERROR.CONNECTION);
          }
        } else {
          resetCourseSelection();
          history.replace(
            `/enrollment/${selectedCareer.studyPlanEnrollmentId}/detail`,
          );
        }
      }
    } catch (error) {
      setErrorValidation(REGISTRATION_ERROR.CONNECTION);
    } finally {
      setIsConfirmationOpen(false);
      setIsFinishing(false);
    }
  }, [
    resetCourseSelection,
    setIsFinishing,
    setIsConfirmationOpen,
    history,
    selectedSections,
    selectedCareer,
    validateCourseRegistration,
    errorSectionsWithoutQuota,
  ]);

  return (
    <>
      {/* BOTONES PARA BORRAR TODA LA SELECCIÓN O CONFIRMAR LA INSCRIPCIÓN DE ASIGNATURAS */}
      <Row className="py-3">
        <Col xs={12} lg={6} className="py-1">
          <Button
            text={t(`${prefix}.btnEraseAllItems`)}
            onClick={removeAllItems}
            outlined
            fullwidth
          />
        </Col>
        <Col xs={12} lg={6} className="py-1">
          <Button
            text={t(`common.btnNext`)}
            onClick={() => setIsConfirmationOpen(true)}
            fullwidth
          />
        </Col>
      </Row>

      {/* MODAL PARA INDICAR ERRORES EN LA INSCRIPCIÓN DE ASIGNATURAS */}
      <Modal
        isOpen={!!errorValidation}
        toggle={() => setErrorValidation(undefined)}
      >
        {errorValidation && (
          <div className="text-center">
            <Row>
              <Col xs={12}>
                <Icon name="warning" size="50px" />
              </Col>
              <Col xs={12} className="pt-4">
                <span className="text-dark fs-22">
                  {t(`${prefix}.errors.${errorValidation}.title`)}
                </span>
              </Col>
              <Col xs={12} className="pt-3 pb-4">
                <span className="fs-18 text-light fw-300">
                  <Trans
                    i18nKey={`${prefix}.errors.${errorValidation}.body`}
                    values={{ contactEmail: helpEmail }}
                    components={{ email: <span className="text-link" /> }}
                  />
                </span>
              </Col>
              {errorValidation === REGISTRATION_ERROR.QUOTA &&
                eliminatedSections.length > 0 && (
                  <Col xs={12} className="text-left pb-5">
                    <p className="fw-300 text-medium fs-14">
                      {t(
                        `${prefix}.errors.${errorValidation}.eliminatedCourses`,
                      )}
                    </p>
                    <div
                      className="outlined-box-primary fs-16 text-dark px-1"
                      style={{ minHeight: '120px' }}
                    >
                      <ul>
                        {eliminatedSections.map((section) => {
                          return (
                            <li key={section.id}>
                              {section.course.code}-{section.name}{' '}
                              {section.course.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Col>
                )}
              <Col xs={12} className="py-1 px-2">
                <Button
                  fullwidth
                  onClick={() => setErrorValidation(undefined)}
                  text={t(`common.btnGotIt`)}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal>

      <ModalConfirmRegistration
        isModalOpen={isConfirmationOpen}
        toggleModal={() => setIsConfirmationOpen(false)}
        onPressAccept={submitRegistration}
        isLoadingAction={isFinishing}
      />
    </>
  );
};

export default FinishRegistration;
