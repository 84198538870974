import Base from '../../components/base/Base';
import { CareerSelectorProvider } from '../../components/careerSelector/parts/CareerSelectorProvider';
import EnrolledCourses from './EnrolledCourses';

const EnrolledCoursesView = () => {
  return (
    <Base>
      <CareerSelectorProvider>
        <EnrolledCourses />
      </CareerSelectorProvider>
    </Base>
  );
};

export default EnrolledCoursesView;
