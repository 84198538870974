import { ReactNode, createContext, useContext, useMemo } from 'react';

import { Career, Period } from '../careerSelector/academicInformationTypes';
import DisplayError from '../info/DisplayError';
import Loading from '../info/Loading';
import { useBaseLoader } from './BaseLoader';

export const BaseContext = createContext<{
  careers: Career[];
  periods?: Period[];
  allPeriods?: Period[];
} | null>(null);

export function useBase() {
  const value = useContext(BaseContext);
  if (!value) {
    throw new Error('Data no cargada en el contexto: BaseContext');
  }
  return value;
}

export const BaseConsumer = BaseContext.Consumer;

export function BaseProvider({ children }: { children: ReactNode }) {
  const { data, error, loading, refresh } = useBaseLoader();

  const value = useMemo(() => {
    if (!data) {
      return null;
    }
    return {
      careers: data.careers,
      periods: data.periods,
      allPeriods: data.allPeriods,
    };
  }, [data]);

  if (error) {
    return (
      <DisplayError
        body={error.code}
        retryAction={refresh}
        loadingAction={loading}
        className="m-auto"
      />
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <DisplayError
        body="No se pudo cargar la información"
        retryAction={refresh}
        loadingAction={loading}
        className="m-auto"
      />
    );
  }

  return <BaseContext.Provider value={value} children={children} />;
}
