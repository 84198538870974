import { OutlinedSelectOptionType } from '@octano/global-ui';
import { useCallback, useContext } from 'react';

import { CourseSelectionContext } from '../providers/CourseSelectionProvider';
import { StudentInfoType } from '../types/courseRegistrationRequestTypes';
import {
  Campus,
  CourseSelectionActionTypes,
  CreditPeriod,
  SelectedCareer,
  SelectedCouse,
  SelectedSection,
} from '../types/courseSelectionTypes';

/**
 * Provee el estado y el manejo de estado de la selección de cursos
 * que realiza el usuario
 */
export const useCourseSelectionState = () => {
  const { state, dispatch } = useContext(CourseSelectionContext);

  const resetCourseSelection = useCallback(() => {
    dispatch({
      type: CourseSelectionActionTypes.RESET_STATE,
    });
  }, [dispatch]);

  const setCampusCareer = useCallback(
    async (campus: Campus | null) => {
      dispatch({
        type: CourseSelectionActionTypes.SET_CAMPUS_CAREER,
        payload: campus,
      });
    },
    [dispatch],
  );

  const setStudentInfo = useCallback(
    async (studentInfo: StudentInfoType) => {
      dispatch({
        type: CourseSelectionActionTypes.SET_STUDENT_INFO,
        payload: studentInfo,
      });
    },
    [dispatch],
  );

  const setCourseState = useCallback(
    (course: SelectedCouse | null) => {
      dispatch({
        type: CourseSelectionActionTypes.SET_COURSE,
        payload: course,
      });
    },
    [dispatch],
  );

  const addSection = useCallback(
    (section: SelectedSection) => {
      dispatch({
        type: CourseSelectionActionTypes.ADD_SECTION,
        payload: section,
      });
    },
    [dispatch],
  );

  const addSectionAllowingRepeated = useCallback(
    (section: SelectedSection) => {
      dispatch({
        type: CourseSelectionActionTypes.ADD_SECTION_ALLOWING_REPEATED,
        payload: section,
      });
    },
    [dispatch],
  );

  const removeSection = useCallback(
    (sectionId: number) => {
      dispatch({
        type: CourseSelectionActionTypes.REMOVE_SECTION,
        payload: sectionId,
      });
    },
    [dispatch],
  );

  const removeAllItems = useCallback(() => {
    dispatch({
      type: CourseSelectionActionTypes.REMOVE_ALL_SECTIONS,
    });
  }, [dispatch]);

  const setSelectedCampus = useCallback(
    (selectedCampus: OutlinedSelectOptionType | undefined) => {
      dispatch({
        type: CourseSelectionActionTypes.SELECT_CAMPUS,
        payload: selectedCampus,
      });
    },
    [dispatch],
  );

  const setCreditsPeriod = useCallback(
    (payload: CreditPeriod) => {
      dispatch({
        type: CourseSelectionActionTypes.SET_CREDITS_PERIOD,
        payload,
      });
    },
    [dispatch],
  );

  const setSelectedCareer = useCallback(
    (payload: SelectedCareer) => {
      dispatch({
        type: CourseSelectionActionTypes.SET_SELECTED_CAREER,
        payload,
      });
    },
    [dispatch],
  );

  return {
    ...state,
    resetCourseSelection,
    setStudentInfo,
    setCampusCareer,
    setCourseState,
    addSection,
    removeSection,
    setSelectedCampus,
    removeAllItems,
    setCreditsPeriod,
    setSelectedCareer,
    addSectionAllowingRepeated,
  };
};
