import clsx from 'clsx';

import { AttendanceStatus } from '../../../types/attendance';

interface Props {
  status: AttendanceStatus;
  size?: number | string;
}

export default function Box({ status, size = 36 }: Props) {
  return (
    <div
      className={clsx('tracking-status-box', {
        'present-bg': status === AttendanceStatus.PRESENT,
        'absent-bg': status === AttendanceStatus.ABSENT,
        'justified-bg': status === AttendanceStatus.JUSTIFIED,
      })}
      style={{ width: size, height: size }}
    ></div>
  );
}
