import { useTranslation } from 'react-i18next';

import { Semester } from '../../api/requests/curriculum';

type ProgressionBySemesterProps = {
  semesters: Semester[];
};

const ProgressionBySemester = ({ semesters }: ProgressionBySemesterProps) => {
  const prefix = 'progressionBySemester';
  const { t } = useTranslation();

  return (
    <div className="g-progression">
      {semesters.map((semester) => {
        return (
          <div className="pr-4" key={`semester_${semester.id}`}>
            <div className="pb-3 px-1">
              <span className="text-capitalize d-block text-center">
                {semester.title}
              </span>
            </div>
            {semester.semesterCourses.map((semesterCourse) => {
              if (semesterCourse.isValidated) {
                semesterCourse.state = 'V';
              }
              return (
                <div
                  className={`course-container ${
                    semesterCourse.state && 'box-' + semesterCourse.state
                  }`}
                  key={`semester_${semester.id}_course_${semesterCourse.id}`}
                >
                  {/* Si el curso es obligatorio o actividad la información se toma desde `course` 
                  de lo contrario se toma desde `optionalSlot` */}
                  <div
                    className="course-name"
                    title={
                      semesterCourse.course
                        ? semesterCourse.course.name
                        : semesterCourse.courseType.name
                    }
                  >
                    {semesterCourse.course
                      ? semesterCourse.course.name
                      : semesterCourse.courseType.name}
                  </div>

                  {semesterCourse.course && (
                    <div className="course-detail pb-0">
                      {semesterCourse.course.shortening}
                    </div>
                  )}
                  <div className="course-detail">
                    {t(`${prefix}.credits`)}:{' '}
                    {semesterCourse.course
                      ? semesterCourse.course.credits
                      : semesterCourse.optionalSlot?.credits}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressionBySemester;
