import { ColumnTable } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../utils/time';
import { DegreeProcessRendition } from '../types';
import BadgeState from './BadgeState';

export function useColumnsRenditions() {
  const prefix = 'degreeProcesses.tableRenditions';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      headers: t<string, Record<string, string>>(`${prefix}.headers`, {
        returnObjects: true,
      }),
      badgeState: t<string, Record<'approved' | 'failed', string>>(
        `${prefix}.badgeState`,
        {
          returnObjects: true,
        },
      ),
    }),
    [t],
  );

  const columns: ColumnTable<DegreeProcessRendition>[] = useMemo(() => {
    const columnWidth = `${100 / 6}%`;
    return [
      {
        columnName: 'date',
        headerText: 'Fecha de Rendición',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => formatDate(row.date, 'DD/MM/YYYY'),
      },
      {
        columnName: 'state',
        headerText: 'Estado',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => {
          if (row.approved) {
            return (
              <BadgeState status="approved" text={texts.badgeState.approved} />
            );
          }
          return <BadgeState status="failed" text={texts.badgeState.failed} />;
        },
      },
      {
        columnName: 'grade',
        headerText: 'Nota',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => row.grade,
      },
      {
        columnName: 'gradeMax',
        headerText: 'Nota Máxima',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => row.gradeMax,
      },
      {
        columnName: 'comment',
        headerText: 'Comentario',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => row.comment,
      },
      {
        columnName: 'creator',
        headerText: 'Editado por',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => row.creator.fullName,
      },
    ];
  }, [texts]);

  return columns;
}
