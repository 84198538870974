import { useCallback, useEffect, useState } from 'react';

/**
 * Hook utilizado para filtrar un array de resultados por texto de búsqueda y páginas
 * Nota: Se despliegan todos los resultados de las primeras X páginas, no solo los resultados de la página actual
 * @param data Array con los datos ha filtrar
 * @param filterFunction Función que se utilizará para filtrar los datos
 * @param resultsPerPage Cantidad de resultados que se mostrarán por página
 * @returns
 */
export const useDataFilter = <T>(
  data: T[],
  filterFunction: (row: T, searchText: string) => boolean,
  resultsPerPage: number,
) => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [resultsByText, setResultsByText] = useState<T[]>([]);
  const [resultsToShow, setResultsToShow] = useState<T[]>([]);

  const filterCourses = useCallback(() => {
    if (searchText.length === 0 || searchText.length > 2) {
      let filteredCourses = data.filter((row) =>
        filterFunction(row, searchText),
      );
      setResultsByText(filteredCourses);
    }
  }, [data, searchText, filterFunction]);

  const setResults = useCallback(() => {
    setResultsToShow(resultsByText.slice(0, resultsPerPage * page));
  }, [resultsByText, page, resultsPerPage]);

  useEffect(() => {
    filterCourses();
  }, [page, filterCourses]);

  /** Si cambia el texto de búsqueda se regresa a la página 1 */
  useEffect(() => {
    setPage(1);
  }, [searchText]);

  useEffect(() => {
    setResults();
  }, [setResults]);

  return {
    results: resultsToShow,
    totalResults: resultsByText.length,
    searchText,
    setSearchText,
    page,
    setPage,
  };
};
