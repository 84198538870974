import { Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

export default function Loading() {
  const { t } = useTranslation();
  const prefix = 'studentAttendance.loading';

  return (
    <div className="d-flex flex-column justify-content-center align-items-center p-5 mt-4">
      <Icon name="circle" className="spin" size={51} />
      <h2 className="fs-22 text-dark text-center mb-3 mt-5">
        {t(`${prefix}.title`)}
      </h2>
      <p className="fs-18 text-light text-center">
        {t(`${prefix}.description`)}
      </p>
    </div>
  );
}
