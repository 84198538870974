import { createElement } from 'react';

import { CertificateType, ReportComponents, ReportType } from '../../types';
import EnrolledStudentActions from './EnrolledStudentActions';
import GradesStudentActions from './GradesStudentActions';
import RegularStudentActions from './RegularStudentActions';

const getActionsByReportType = (reportType: ReportType) => {
  const COMPONENTS: ReportComponents = {
    'regular-student': RegularStudentActions,
    'enrolled-student': EnrolledStudentActions,
    'grades-student': GradesStudentActions,
    'degree-student': RegularStudentActions,
    'titled-student': RegularStudentActions,
  };

  return COMPONENTS[reportType];
};

interface Props {
  studyPlanEnrollmentId: number;
  report: CertificateType;
}

export default function ReportActionsCell({
  studyPlanEnrollmentId,
  report,
}: Props) {
  const { code: reportType } = report;
  return createElement(getActionsByReportType(reportType), {
    reportType,
    studyPlanEnrollmentId,
  });
}
