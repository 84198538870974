import { useCallback, useContext } from 'react';

import { StepContext } from '../providers/StepProvider';
import { StepActionType } from '../types/stepTypes';

export function useStepState() {
  const { state, dispatch } = useContext(StepContext);

  const nextStep = useCallback(() => {
    dispatch({
      type: StepActionType.NEXT_STEP,
    });
  }, [dispatch]);

  const prevStep = useCallback(() => {
    dispatch({
      type: StepActionType.PREV_STEP,
    });
  }, [dispatch]);

  const setCurrentStep = useCallback(
    (currentStep: number) => {
      dispatch({
        type: StepActionType.SET_STEP,
        payload: currentStep,
      });
    },
    [dispatch],
  );

  return {
    ...state,
    nextStep,
    prevStep,
    setCurrentStep,
  };
}
