import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import Title from '../../components/text/Title';
import SearchingCoursesProvider from '../../providers/SearchingCoursesProvider';
import SearchingProcess from './parts/SearchingProcess';

const CoursesSearching = () => {
  const prefix = 'courseSearching';
  const { t } = useTranslation();

  return (
    <>
      <SearchingCoursesProvider>
        <Title text={t(`${prefix}.title`)} />
        <Card className="mt-3 py-4 px-3 px-md-5">
          <SearchingProcess />
        </Card>
      </SearchingCoursesProvider>
    </>
  );
};

export default CoursesSearching;
