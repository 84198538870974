import { SelectOptionType } from '@octano/global-ui';

/**
 * Función para formatear las opciones del select de países
 * @param data Array con países {id, name}
 * @returns Array de opciones con los valores (value, label)
 */
export function formatCountryOptions(
  data: { id: string; name: string }[],
): SelectOptionType[] {
  return data.map(
    (row: any): SelectOptionType => ({
      value: row.id,
      label: row.name,
    }),
  );
}

export function getSelectedOption(
  id: string | number | undefined,
  options: SelectOptionType[],
) {
  return options.find((row) => row.value === id);
}
