import { addToast } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { getCreditsByPeriod } from '../../../api/requests/periods';
import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';

const prefix = 'courseRegistration';

const PeriodMinAndMaxCreditsCard = ({
  setEmailHelp,
}: {
  setEmailHelp: (email: string) => void;
}) => {
  const { t } = useTranslation();
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const { creditsPeriod, setCreditsPeriod } = useCourseSelectionState();

  const getPeriodCredits = useCallback(async () => {
    const { data, error } = await getCreditsByPeriod(studyPlanEnrollmentId);
    if (data) {
      setEmailHelp(data.helpEmail);
      setCreditsPeriod({
        creditMax: data.creditMax,
        creditMin: data.creditMin,
      });
    }
    if (error) {
      addToast({
        color: 'danger',
        icon: 'error',
        text: t('common.displayError.errorUnexpected'),
      });
    }
  }, [studyPlanEnrollmentId, setEmailHelp, setCreditsPeriod, t]);

  useEffect(() => {
    getPeriodCredits();
  }, [getPeriodCredits]);

  return (
    <div className="align-items-center justify-content-center py-2">
      <div className="outlined-box-primary">
        <Row className="fs-16">
          <Col xs={12} lg={6} className="d-flex justify-content-between">
            <span className="text-dark">
              <Trans t={t}>{t(`${prefix}.min`)}</Trans>
            </span>
            <span>{creditsPeriod.creditMin}</span>
          </Col>
          <Col xs={12} lg={6} className="d-flex justify-content-between">
            <span className="text-dark">
              <Trans t={t}>{t(`${prefix}.max`)}</Trans>
            </span>
            <span>{creditsPeriod.creditMax}</span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PeriodMinAndMaxCreditsCard;
