import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { requestValidateAccount } from '../api/requests/courseRegistration';
import {
  STATUS_TO_ENROLL,
  StatusToEnroll,
} from '../api/requests/studentManagement';
import DisplayError from '../components/info/DisplayError';
import Loading from '../components/info/Loading';
import RoutesByLayout from '../components/routes/RoutesByLayout';
import { HELP_EMAIL } from '../config/constants';
import { PathsLayouts } from '../config/routes';
import { useLoadingState } from '../hooks/useLoadingState';
import { formatDate } from '../utils/time';

const prefix = 'enrollmentLayout';

const CoursesEnrollmentContentLayout = () => {
  const { t } = useTranslation();
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const history = useHistory();

  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const [isStudent, setIsStudent] = useState<boolean>(false);
  const [statusToEnroll, setStatusToEnroll] = useState<StatusToEnroll>();

  const validateAccount = useCallback(async () => {
    const { data, error } = await requestValidateAccount(studyPlanEnrollmentId);
    if (data) {
      setIsStudent(data.data.isStudent);
      setErrorLoading(undefined);
    }
    if (error) {
      if (error.code === 'HTTP_ERROR' && error.status === 403) {
        setIsStudent(false);
      }
      if (error.data.code) {
        setStatusToEnroll(error.data);
      } else {
        setErrorLoading(error.code);
      }
    }
    setLoading(false);
  }, [setErrorLoading, setLoading, studyPlanEnrollmentId]);

  useEffect(() => {
    validateAccount();
  }, [validateAccount]);

  if (loading) {
    return <Loading insideCard />;
  }

  if (errorLoading) {
    return <DisplayError insideCard body={errorLoading} />;
  }

  if (isStudent) {
    return (
      <RoutesByLayout
        pathLayout={PathsLayouts.coursesEnrollment}
        defaultRootPath={PathsLayouts.coursesEnrollment.replace(
          ':studyPlanEnrollmentId',
          studyPlanEnrollmentId,
        )}
      />
    );
  }

  if (statusToEnroll) {
    if (statusToEnroll.code === STATUS_TO_ENROLL.NOT_FOUND) {
      return (
        <DisplayError
          insideCard
          title={t(`${prefix}.notFoundPeriod`)}
          body={() => (
            <p className="fs-18 fw-300">
              <Trans
                i18nKey={`${prefix}.notFoundPeriodBody`}
                values={{
                  email: statusToEnroll.email,
                }}
              />
            </p>
          )}
          textButton={t('common.btnGotIt')}
          retryAction={() => history.push('/')}
        />
      );
    }
    if (statusToEnroll.code === STATUS_TO_ENROLL.INVALID) {
      // Está en un estado distinto de regular
      return (
        <DisplayError
          insideCard
          title={t(`${prefix}.invalidStatusTitle`)}
          body={() => (
            <p className="fs-18 fw-300">
              <Trans
                i18nKey={`${prefix}.invalidStatusBody`}
                values={{
                  email: statusToEnroll.email,
                }}
              />
            </p>
          )}
          textButton={t('common.btnGotIt')}
          retryAction={() => history.push('/')}
        />
      );
    }
    if (statusToEnroll.code === STATUS_TO_ENROLL.DEBTOR) {
      // Está con bloqueo financiero
      return (
        <DisplayError
          insideCard
          title={t(`${prefix}.debtorTitle`)}
          body={t(`${prefix}.debtorBody`)}
          textButton={t('common.btnGotIt')}
          retryAction={() => history.push('/')}
        />
      );
    }
    if (statusToEnroll.code === STATUS_TO_ENROLL.INCOMING) {
      // Si está ANTES del plazo se indica que la inscripción está fuera de plazo
      // con las fechas del próximo periodo
      return (
        <DisplayError
          insideCard
          title={t(`${prefix}.outPeriodTitle`)}
          body={() => (
            <p className="fs-18 fw-300">
              <Trans
                i18nKey={`${prefix}.outPeriodBody`}
                values={{
                  startDate: statusToEnroll.range[0]
                    ? formatDate(statusToEnroll.range[0], 'DD-MM-YYYY')
                    : '',
                  endDate: statusToEnroll.range[1]
                    ? formatDate(statusToEnroll.range[1], 'DD-MM-YYYY')
                    : '',
                }}
              />
            </p>
          )}
          textButton={t('common.btnGotIt')}
          retryAction={() => history.push('/')}
        />
      );
    }
    if (statusToEnroll.code === STATUS_TO_ENROLL.EXCEEDED) {
      // Si está DESPUES del plazo se indica que la inscripción está fuera de plazo
      return (
        <DisplayError
          insideCard
          title={t(`${prefix}.exceededTitle`)}
          body={() => (
            <p className="fs-18 fw-300">
              <Trans
                i18nKey={`${prefix}.exceededBody`}
                values={{
                  email: statusToEnroll.email,
                  startDate: statusToEnroll.range[0]
                    ? formatDate(statusToEnroll.range[0], 'DD-MM-YYYY')
                    : '',
                  endDate: statusToEnroll.range[1]
                    ? formatDate(statusToEnroll.range[1], 'DD-MM-YYYY')
                    : '',
                }}
              />
            </p>
          )}
          textButton={t('common.btnGotIt')}
          retryAction={() => history.push('/')}
        />
      );
    }
  }

  return (
    <DisplayError
      insideCard
      title={t('userUnauthorized.title')}
      body={() => (
        <p className="fs-18 fw-300">
          {t('userUnauthorized.body')}{' '}
          <span className="text-link">{HELP_EMAIL}</span>
        </p>
      )}
    />
  );
};

export default CoursesEnrollmentContentLayout;
