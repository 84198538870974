import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateStepsProps, StepInformation } from '../types/stepTypes';
import { useStepState } from './useStepState';

export function useCreateTuitionContinuitySteps(allSteps: CreateStepsProps) {
  const { t } = useTranslation();
  const { currentStep } = useStepState();

  const [requiredSteps, setRequiredSteps] = useState<string[]>([]);
  /**
   * callback para editar los pasos disponibles
   */
  const setAvailableStepsAndCurrentIndex = useCallback(
    (requiredSteps: string[], currentStep: string): number => {
      setRequiredSteps(requiredSteps);
      return requiredSteps.findIndex((step) => step === currentStep);
    },
    [setRequiredSteps],
  );

  /**
   * Contiene los pasos
   */
  const stepsCreate = useMemo<StepInformation[]>(() => {
    const { translationPrefix, allAvailableSteps } = allSteps;
    return requiredSteps.reduce((acc, id) => {
      const step = allAvailableSteps.find((step) => step.id === id);
      if (step) {
        acc.push({
          id,
          name: t(`${translationPrefix}.${id}`),
          component: step.component,
        });
      }
      return acc;
    }, new Array<StepInformation>());
  }, [t, allSteps, requiredSteps]);

  /**
   * Contiene dos arrays con las partes de los pasos
   * (Un array con los nombres de los pasos y
   * un array con los componentes asociados a cada paso)
   */
  const availableParts = useMemo(() => {
    const namesSteps: string[] = [];
    const componentsSteps: React.FunctionComponent[] = [];

    stepsCreate.forEach((step) => {
      namesSteps.push(step.name);
      componentsSteps.push(step.component);
    });
    return { namesSteps: namesSteps, componentsSteps };
  }, [stepsCreate]);

  const getIndexStep = useCallback(
    /**
     * Una función que devuelve el índice de stepName en la matriz stepsCreateOfferProcess.
     * @param {string} stepId - nombre del paso al que se le recuperará el índice
     * @return {number} índice del paso ingresado
     * **/
    (stepId: string): number => {
      return stepsCreate.findIndex((step) => step.id === stepId);
    },
    [stepsCreate],
  );

  /**
   * El paso actual en base a la variable currentStep
   */
  const CurrentStepComponent = useMemo(() => {
    return availableParts.componentsSteps[currentStep];
  }, [currentStep, availableParts]);

  return {
    stepsCreateOfferProcess: stepsCreate,
    ...availableParts,
    CurrentStepComponent,
    getIndexStep,
    setAvailableStepsAndCurrentIndex,
  };
}
