import { Table as TableCore } from '@octano/global-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Table as TableCollapsable } from '../../../components/table-collapsable';
import { useActivitiesResumenColumns } from '../hooks/UseActivitiesResumenColumns.hook';
import { useActivitiesResumenColumnsRedentions } from '../hooks/UseActivitiesResumenColumnsRedentions.hook';
import { InterimDegreeActivity } from '../interfaces/interim-degree-activity.interface';
import { InterimDegreeStudentActivityRow } from '../interfaces/interim-degree-authorized-student-activities-details.interface';
import { InterimDegreeStudentActivity } from '../interfaces/interim-degree-student-activity.interface';

export interface ActivitiesResumenTableProps {
  data: InterimDegreeStudentActivityRow[];
  newRecordEnabled?: boolean;
  openAddNewRecord?: (interimDegreeActivity: InterimDegreeActivity) => any;
}

export default function ActivitiesResumenTable({
  data,
  newRecordEnabled = false,
  openAddNewRecord,
}: ActivitiesResumenTableProps) {
  const { t } = useTranslation();

  const columns = useActivitiesResumenColumns();
  const columnsRenditions = useActivitiesResumenColumnsRedentions({
    openAddNewRecord,
  });

  const dataBuildEmptyRow = useCallback(
    (studentActivity: InterimDegreeStudentActivityRow | null) => ({
      buildEmptyRow: true,
      interimDegreeActivity: studentActivity?.interimDegreeActivity,
    }),
    [],
  );

  return (
    <TableCollapsable<InterimDegreeStudentActivityRow>
      columns={columns}
      secondColumn={{
        tdClassName: 'px-4',
        columnName: 'quota',
        headerText: t(`table.quota`),
        cellFormat: ({ row }) => {
          const buildEmptyRow = newRecordEnabled
            ? dataBuildEmptyRow(row)
            : dataBuildEmptyRow(null);
          return (
            <TableCore<InterimDegreeStudentActivity>
              borderless={false}
              color="transparent"
              striped
              columns={columnsRenditions}
              data={
                newRecordEnabled ? [...row.history, buildEmptyRow] : row.history
              }
            />
          );
        },
      }}
      data={data}
    />
  );
}
