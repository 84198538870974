import { ScheduleLegends } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

const CourseTypeLegends = () => {
  const prefix = 'scheduleLegends';
  const { t } = useTranslation();

  const legends = [
    { id: 1, name: t(`${prefix}.course`) },
    { id: 2, name: t(`${prefix}.assistantship`) },
    { id: 3, name: t(`${prefix}.lab`) },
    { id: 4, name: t(`${prefix}.field`) },
    { id: 5, name: t(`${prefix}.workshop`) },
    { id: 6, name: t(`${prefix}.practice`) },
    { id: 7, name: t(`${prefix}.thesis`) },
    { id: 8, name: t(`${prefix}.other`) },
  ];

  return <ScheduleLegends legends={legends} />;
};

export default CourseTypeLegends;
