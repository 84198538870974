import { Select, TextInput } from '@octano/global-ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { COUNTRY_CHILE_ID } from '../../../../../api/requests/parameters';
import SectionTitle from '../../../../../components/text/SectionTitle';
import { useFilteredCommunes } from '../../../../../hooks/useFilteredCommunes';
import { useParameters } from '../../../../../hooks/useParameters';
import { useValidations } from '../../../../../hooks/useValidations';
import { EducationalBackgroundType } from '../../../../../types/studentFileSectionTypes';
import { FieldsStudentFile } from '../../../../../types/studentFileTypes';
import { getSelectedOption } from '../../../../../utils/selectFormat';

type EducationalBackgroundNoSuaProps = {
  educationalBackground?: EducationalBackgroundType;
  disableInputs?: boolean;
};

/**
 * Sección de antecedentes educacionales para estudiante
 */
const EducationalBackground = ({
  educationalBackground,
  disableInputs = false,
}: EducationalBackgroundNoSuaProps) => {
  const prefix =
    'tuitionContinuity.steps.studentFile.educationalBackgroundSection';
  const { t } = useTranslation();
  const {
    countryOptions,
    communeOptions,
    regionOptions,
    institutionTypeOptions,
  } = useParameters();
  const { control, watch } = useFormContext<FieldsStudentFile>();
  const { msgValidations } = useValidations();

  const selectedCountry = watch('countryHighSchool');
  const [selectedRegion] = watch(['regionHighSchool']);

  const filteredCommunes = useFilteredCommunes(selectedRegion);

  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="nameHighSchool"
            label={t(`${prefix}.highSchool`)}
            value={educationalBackground?.highSchool}
            disabled={disableInputs}
            control={control}
            maxLength={250}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="countryHighSchool"
            label={t(`${prefix}.country`)}
            options={countryOptions}
            value={getSelectedOption(
              educationalBackground?.country?.id,
              countryOptions,
            )}
            disabled={disableInputs}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        {selectedCountry?.value === COUNTRY_CHILE_ID && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <Select
                name="regionHighSchool"
                label={t(`${prefix}.region`)}
                options={regionOptions}
                value={getSelectedOption(
                  educationalBackground?.region?.id,
                  regionOptions,
                )}
                disabled={disableInputs}
                rules={{ required: msgValidations.required }}
                control={control}
                shouldUnregister={true}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <Select
                name="communeHighSchool"
                label={t(`${prefix}.commune`)}
                options={filteredCommunes}
                value={getSelectedOption(
                  educationalBackground?.commune?.id,
                  communeOptions,
                )}
                rules={{ required: msgValidations.required }}
                control={control}
                disabled={disableInputs}
                shouldUnregister={true}
              />
            </Col>
          </>
        )}
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="typeHighSchool"
            label={t(`${prefix}.typeHighSchool`)}
            options={institutionTypeOptions}
            value={getSelectedOption(
              educationalBackground?.institutionType?.id,
              institutionTypeOptions,
            )}
            disabled={disableInputs}
            rules={{ required: msgValidations.required }}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="graduationYear"
            label={t(`${prefix}.graduationYear`)}
            value={educationalBackground?.graduationYear}
            control={control}
            disabled={disableInputs}
            rules={{ required: msgValidations.required }}
            maxLength={5}
          />
        </Col>
      </Row>
    </>
  );
};

export default EducationalBackground;
