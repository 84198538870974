import { UserAction, UserActionTypes, UserState } from '../types/userTypes';
import { getJWT, isUserAuthorizedTo } from '../utils/auth';

export const DEFAULT_VALUE: UserState = {
  isLogged: getJWT() ? true : false,
  isSessionExpired: false,
  userData: undefined,
  isAuthorizedTo: (rp: string[], allPermisionsRequired?: boolean) => true,
};

const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionTypes.RESET_STATE:
      return {
        ...DEFAULT_VALUE,
        isLogged: false,
      };

    case UserActionTypes.SET_IS_LOGGED:
      return {
        ...state,
        isLogged: action.payload,
      };

    case UserActionTypes.SET_IS_SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.payload,
      };

    case UserActionTypes.SET_FULL_NAME: {
      const userData = state.userData && {
        ...state.userData,
        fullName: action.payload,
      };

      return {
        ...state,
        userData,
      };
    }

    case UserActionTypes.SET_USER_DATA: {
      const prevUserData = state.userData;
      const userData = prevUserData
        ? { ...prevUserData, ...action.payload }
        : (action.payload as UserState['userData']);

      // Funciones de utilidad para los permisos
      const isAuthorizedTo = (
        requiredPermissions: string[],
        allPermisionsRequired?: boolean,
      ) => {
        return isUserAuthorizedTo(
          userData?.permissions || [],
          requiredPermissions,
          allPermisionsRequired,
        );
      };

      return {
        ...state,
        userData,
        isAuthorizedTo: isAuthorizedTo,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
