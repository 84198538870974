import { Button, Schedule, ScheduleType, useMobile } from '@octano/global-ui';
import clsx from 'clsx';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import { getModules } from '../../api/requests/courseModules';
import { useBase } from '../../components/base/BaseProvider';
import StudyPlanBox from '../../components/box/StudyPlanBox';
import CourseTypeLegends from '../../components/calendar/CourseTypeLegends';
import SectionsWithSchedule from '../../components/courseEnrollment/SectionsWithSchedule';
import SelectedSections from '../../components/courseEnrollment/SelectedSections';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import SectionTitle from '../../components/text/SectionTitle';
import Title from '../../components/text/Title';
import { CONTACT_EMAIL } from '../../config/constants';
import { SelectedSection } from '../../types/courseSelectionTypes';
import { ModuleType, SectionScheduleTypeEnum } from '../../types/sectionType';
import sortSectionSchedule from '../../utils/sortSectionSchedule';
import { isNumericText } from '../../utils/text';
import { requestEnrollmentCourseResume } from './request';

const prefix = 'enrolledCourses';

const EnrolledCourses = () => {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const { studyPlanEnrollmentId } = useParams<{
    studyPlanEnrollmentId: string;
  }>();
  const history = useHistory();

  const [modules, setModules] = useState<ModuleType[] | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [selectedSections, setSelectedSections] = useState<
    SelectedSection[] | null
  >(null);
  const [loadingResume, setLoadingResume] = useState<boolean>(true);
  const [errorResume, setErrorResume] = useState<boolean>(false);
  const [errorModules, setErrorModules] = useState<boolean>(false);

  const { careers } = useBase();
  const selectedCareer = useMemo(() => {
    return careers.find(
      (c) => String(c.studyPlanEnrollmentId) === studyPlanEnrollmentId,
    );
  }, [careers, studyPlanEnrollmentId]);

  const [studyPlan, setStudyPlan] = useState<{
    studyPlanName: string;
    mention?: string;
  }>();

  const handlePrint = useCallback(async () => {
    try {
      setDownloading(true);
      const print = document.getElementById('print');
      if (print) {
        const doc = new jsPDF('p', 'px', 'a4');
        print.style.display = 'block';
        const canvas = await html2canvas(print);
        const imgCalendar = canvas.toDataURL('image/png');
        const imgWidth = doc.internal.pageSize.width * 0.9;
        const imgHeight = (imgWidth * canvas.height) / canvas.width;
        doc.text(t(`${prefix}.title`), 20, 25);
        doc.addImage(imgCalendar, 'PNG', 20, 40, imgWidth, imgHeight);
        print.style.display = 'none';
        doc.save(t(`${prefix}.title`) + '.pdf');
      }
    } catch (error) {
      // TODO: Mostrar error al generar el archivo
    } finally {
      setDownloading(false);
    }
  }, [setDownloading, t]);

  /**
   * Obtiene los módulos de horarios para cargar las
   * asignaturas en el horario.
   */
  const getModulesInfo = useCallback(async () => {
    const { data, error } = await getModules();
    if (data?.data) {
      setModules(data.data);
    }
    if (error) {
      setErrorModules(true);
    }
  }, []);

  useEffect(() => {
    getModulesInfo();
  }, [getModulesInfo]);

  /**
   * Obtiene la información del proceso de inscripción de asignaturas
   * registrado para una carrera del alumno en el periodo actual.
   */
  const getEnrollmentResume = useCallback(async () => {
    if (studyPlanEnrollmentId) {
      const { data, error } = await requestEnrollmentCourseResume(
        studyPlanEnrollmentId,
      );
      if (data) {
        setSelectedSections(
          data.map((s) => {
            return {
              id: s.sectionData.id,
              name: s.sectionData.name,
              maximumStudents: s.sectionData.maximumStudent,
              slots: s.sectionData.slots,
              sectionSchedules: s.sectionData.sectionSchedules,
              professors: s.sectionData.professors,
              campus: s.sectionData.campus,
              course: { ...s.course, types: [s.course.types] },
            };
          }),
        );
        setStudyPlan({
          studyPlanName: selectedCareer?.name ?? '',
          mention: selectedCareer?.mentions[0]?.name,
        });
        setErrorResume(false);
      }
      if (error) {
        setErrorResume(true);
      }
      setLoadingResume(false);
    }
  }, [selectedCareer, studyPlanEnrollmentId]);

  useEffect(() => {
    if (studyPlanEnrollmentId && isNumericText(studyPlanEnrollmentId)) {
      getEnrollmentResume();
    } else {
      history.push('/');
    }
  }, [getEnrollmentResume, history, studyPlanEnrollmentId]);

  // Array con todos los horarios de las secciones inscritas
  const schedules: ScheduleType[] = useMemo(() => {
    if (!selectedSections) {
      return [];
    }
    return selectedSections.flatMap((section: SelectedSection) => {
      return sortSectionSchedule(section.sectionSchedules)
        .filter((schedule) => schedule.type === SectionScheduleTypeEnum.Default)
        .map((schedule) => {
          return {
            sectionId: section.id,
            scheduleId: schedule.id,
            courseCode: section.course.code,
            sectionName: section.name,
            day: schedule.day,
            activityId: schedule.activity.id,
            moduleStartId: schedule.startModule.id,
            moduleEndId: schedule.endModule
              ? schedule.endModule.id
              : schedule.startModule.id,
          };
        });
    });
  }, [selectedSections]);

  if (loadingResume) {
    return <Loading insideCard />;
  }
  if (errorResume || errorModules || !selectedSections || !modules) {
    return (
      <DisplayError
        insideCard
        title={t(`${prefix}.errorLoading.title`)}
        body={t(`${prefix}.errorLoading.body`)}
      />
    );
  }
  if (selectedSections.length === 0) {
    return (
      <DisplayError
        insideCard
        title={t(`${prefix}.noData.title`)}
        body={() => (
          <div>
            <p className="fs-18 fw-300">{t(`${prefix}.noData.body`)}</p>
            <br />
            <Button
              text={t(`${prefix}.noData.btn`)}
              onClick={() => history.push('enrollment/courses')}
            />
          </div>
        )}
      />
    );
  } else {
    return (
      <>
        <Title text={t(`${prefix}.title`)} />
        <Card className="mt-3 py-4 px-3 px-md-5">
          <Row>
            <Col xs={12} sm={3} className="pb-2"></Col>
            <Col xs={12} sm={9} className="pb-2">
              <StudyPlanBox
                studyPlan={studyPlan?.studyPlanName || ''}
                mention={studyPlan?.mention}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="pt-3 pt-md-1">
              <SectionTitle text={t(`${prefix}.subtitle`)} />
            </Col>
            <Col xs={12}>
              <p className="fs-18">
                <Trans
                  i18nKey={`${prefix}.successText`}
                  values={{ contactEmail: CONTACT_EMAIL }}
                  components={{ email: <span className="text-link" /> }}
                />
              </p>
            </Col>
            <Col xs={12}>
              <div>
                {selectedSections.length > 0 && (
                  <Row>
                    <Col xs={12} xl={7} className="pb-3">
                      <Row>
                        <Col xs={12}>
                          <Schedule schedules={schedules} modules={modules} />
                        </Col>
                        <Col xs={12} className="pt-3">
                          <CourseTypeLegends />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} xl={5}>
                      <Row>
                        <Col xs={12}>
                          <SelectedSections
                            mode="VIEW"
                            selectedSections={selectedSections}
                          />
                        </Col>
                      </Row>
                      <div
                        className="d-flex align-items-center justify-content-between flex-wrap py-3"
                        style={{ gap: 12 }}
                      >
                        <Button
                          text={t(`${prefix}.downloadSchedule`)}
                          onClick={handlePrint}
                          size="md"
                          icon="download"
                          outlined
                          loading={downloading}
                          style={{ width: 199 }}
                          className={clsx('px-2', isMobile && 'w-100')}
                        />
                        <Button
                          text={t(`courseRegistration.goBackToPortal`)}
                          size="md"
                          style={{ width: 199 }}
                          className={clsx('px-2', isMobile && 'w-100')}
                          onClick={() => history.push('/')}
                        />
                      </div>
                    </Col>
                  </Row>
                )}

                {/* Este es el contenido que se descarga en el PDF*/}
                <div className="print-hidden-container">
                  <div id="print" style={{ display: 'none', width: '1100px' }}>
                    <Row>
                      <Col xs={6}>
                        <span className="fs-20 text-uppercase text-primary">
                          {t('common.terms.studyPlanName')}:{' '}
                        </span>
                        <br />
                        <span className="fs-18">
                          {studyPlan?.studyPlanName}
                        </span>
                      </Col>
                      <Col xs={6}>
                        {studyPlan?.mention && (
                          <>
                            <span className="fs-20 text-uppercase text-primary">
                              {t('common.terms.mention')}:{' '}
                            </span>
                            <br />
                            <span className="fs-18">{studyPlan.mention}</span>
                          </>
                        )}
                      </Col>
                    </Row>
                    <div className="pb-4">
                      <p className="fs-20"></p>
                    </div>
                    <Schedule schedules={schedules} modules={modules} />
                    <br />
                    <CourseTypeLegends />
                    <br />
                    <SectionsWithSchedule sections={selectedSections} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
};

export default EnrolledCourses;
