import { Button, SelectOptionType, addToast } from '@octano/global-ui';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { HTTP_STATUS_CODE } from '../../../api/request';
import { createPetition } from '../../../api/requests/petitions';
import { useBase } from '../../../components/base/BaseProvider';
import { useFetch } from '../../../components/contexts/FetchContext';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import {
  PaginationQuery,
  PaginationRequestType,
} from '../../../types/paginationRequestType';
import {
  CreatePetitionData,
  PetitionData,
  PetitionInf,
} from '../../../types/petitions';
import PetitionForm from './PetitionForm';

export interface CreatePetitionFormProps extends CreatePetitionData {
  close: () => void;
  error?: string;
  loading: boolean;
}
export default function CreatePetitionForm({
  types,
  cellPhone,
  email,
  close,
  error,
  loading,
}: CreatePetitionFormProps) {
  const { careers } = useBase();
  const careerOptions = useMemo<SelectOptionType[]>(
    () =>
      careers.map((c) => ({
        value: c.studyPlanEnrollmentId,
        label: c.name,
      })),
    [careers],
  );

  const methods = useForm<PetitionData>({
    mode: 'onSubmit',
    defaultValues: {
      studyPlanEnrollment:
        careerOptions.length === 1 ? careerOptions[0] : undefined,
    },
  });
  const { reset } = methods;

  const typeOptions = useMemo<SelectOptionType[]>(
    () => types.map((t) => ({ value: t.id, label: t.name })),
    [types],
  );
  const { t } = useTranslation();

  const text = useMemo(() => {
    const prefix = 'petitions.modal.newPetition';
    return {
      cancel: t(`${prefix}.cancel`),
      save: t(`${prefix}.save`),
      message: {
        error: t(`${prefix}.message.error`),
        success: t(`${prefix}.message.success`),
      },
    };
  }, [t]);

  const { updateQuery } = useFetch<
    PaginationQuery,
    PaginationRequestType<PetitionInf>
  >();
  const [loadingSave, setLoading] = useState(false);

  const clearForm = useCallback(() => {
    reset({
      studyPlanEnrollment: undefined,
      cellPhone,
      email,
    });
  }, [reset, cellPhone, email]);

  const onSubmit = useCallback(
    async ({
      cellPhone,
      email,
      doc,
      type,
      title,
      studyPlanEnrollment,
      description,
    }: PetitionData) => {
      setLoading(true);
      const { error, status } = await createPetition({
        email,
        title,
        description,
        doc,
        phone: cellPhone,
        typeId: type.value,
        studyPlanEnrollmentId: studyPlanEnrollment.value,
      });
      if (status === HTTP_STATUS_CODE.CREATED) {
        addToast({
          icon: 'success',
          color: 'success',
          text: text.message.success,
        });
        close();
        clearForm();
        updateQuery({ items_per_page: 10, page: 0 });
      } else if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: text.message.error,
        });
      }
      setLoading(false);
    },
    [close, updateQuery, text, clearForm, setLoading],
  );

  /* useEffect(() => {
    if (selectedCareer) {
      clearForm();
    }
  }, [clearForm, selectedCareer]); */
  if (error) {
    return <DisplayError insideCard loadingAction={loading} body={error} />;
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Row className="mt-4 mb-1">
          <PetitionForm
            careerOptions={careerOptions}
            typeOptions={typeOptions}
          />
          <Col md={6}>
            <Button
              type="button"
              className="w-100"
              outlined
              loading={loadingSave}
              onClick={() => {
                close();
                clearForm();
              }}
              text={text.cancel}
            />
          </Col>
          <Col md={6}>
            <Button
              type="submit"
              className="w-100"
              color="primary"
              loading={loadingSave}
              text={text.save}
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
}
