import { Button, addToast, useMobile } from '@octano/global-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HTTP_STATUS_CODE } from '../../../../api/request';
import { downloadFromURL } from '../../../../utils/blob';
import { ReportType } from '../../types';
import GeneratingFileModal from '../GeneratingFileModal';
import NoResultsModal from '../NoResultsModal';
import NotRegularStModal from '../NotRegularStModal';
import { createReportDownloadHandler } from './handlerCreator';

enum ModalType {
  NONE,
  GENERATING_FILE,
  NO_CERTIFICATE,
  NOT_REGULAR_STUDENT,
}

interface IGradesStudentActionsProps {
  studyPlanEnrollmentId: number;
  reportType: ReportType;
}

export default function GradesStudentActions({
  studyPlanEnrollmentId,
  reportType,
}: IGradesStudentActionsProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [openedModal, setOpenedModal] = useState<ModalType>();

  const handleReportDownload = createReportDownloadHandler({
    beforeDownload: () => setOpenedModal(ModalType.GENERATING_FILE),
    afterDownload: ({ status }: { url: string; status: number }) => {
      if (status === HTTP_STATUS_CODE.NO_CONTENT) {
        setOpenedModal(ModalType.NO_CERTIFICATE);
      } else {
        setOpenedModal(ModalType.NONE);
      }
    },
    onSuccess: ({ url }) => {
      if (url) {
        downloadFromURL(url);
      }
    },
    onError: () => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`reports.errors.errorGeneratingFile`),
      });
    },
  });

  return (
    <>
      <NotRegularStModal
        isOpen={openedModal === ModalType.NOT_REGULAR_STUDENT}
      />
      <NoResultsModal
        isOpen={openedModal === ModalType.NO_CERTIFICATE}
        onConfirm={() => setOpenedModal(ModalType.NONE)}
        texts={{
          title: t(`reports.gradesStudent.noResults.title`),
          description: t(`reports.gradesStudent.noResults.description`),
        }}
      />
      <GeneratingFileModal isOpen={openedModal === ModalType.GENERATING_FILE} />
      <div
        className="d-flex flex-wrap align-items-center justify-content-center "
        style={{ gap: 12 }}
      >
        <Button
          text={t(`common.actions.download`)}
          icon="download"
          size="sm"
          outlined
          style={{ width: isMobile ? '90%' : 123 }}
          onClick={() =>
            handleReportDownload(studyPlanEnrollmentId, reportType)
          }
        />
      </div>
    </>
  );
}
