import { Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, UncontrolledTooltip } from 'reactstrap';

import { PetitionStatus } from '../../../types/petitions';

export interface PetitionStatusBadgeProps {
  status: PetitionStatus;
  name: string | number;
}
export default function PetitionStatusBadge({
  status,
  name,
}: PetitionStatusBadgeProps) {
  const { t } = useTranslation();
  const data = useMemo<{ color: string; text: string; tooltip: string }>(() => {
    const colors: Record<PetitionStatus, string> = {
      [PetitionStatus.Received]: '#E0EEFD',
      [PetitionStatus.InProcess]: '#FFE6A1',
      [PetitionStatus.Waiting]: '#CCCCCC',
      [PetitionStatus.Resolved]: '#CEF0DA',
    };
    return {
      color: colors[status],
      text: t(`petitions.table.status.${status}`),
      tooltip: t(`petitions.table.tooltips.${status}`),
    };
  }, [status, t]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <Badge
          className="fs-16 fw-400 py-2 px-4 text-dark"
          style={{ backgroundColor: data.color, width: 110 }}
          pill
        >
          {data.text}
        </Badge>
        <div className="ml-3" id={`inputTooltip_${name}`}>
          <Icon name="information_input" size="1rem" />
          <UncontrolledTooltip
            placement="bottom"
            target={`inputTooltip_${name}`}
            fade={false}
          >
            {data.tooltip}
          </UncontrolledTooltip>
        </div>
      </div>
    </>
  );
}
