export type AccountType = {
  id: number;
  name: string;
  paternalLastName: string;
  maternalLastName: string;
  paternalPreference: boolean;
  fullName: string;
};

export type SectionTeacher = {
  id: number;
  account: AccountType;
};

export type ModuleType = {
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  shortening: string;
};

export type ClassroomType = {
  id: number;
  name: string;
};

export type ActivityType = {
  id: number;
  name: string;
};

export enum SectionScheduleTypeEnum {
  Default = 'default',
  Recovery = 'recovery',
}

export type SectionScheduleType = {
  id: number;
  day: string;
  startModule: ModuleType;
  endModule: ModuleType | null;
  classroom: ClassroomType;
  activity: ActivityType;
  teacher: SectionTeacher;
  type: SectionScheduleTypeEnum;
};

export type SectionType = {
  id: number;
  name: string;
  maximumStudents: number;
  slots: number;
  campus: {
    id: number;
    name: string;
    code: string;
  };
  professors: SectionTeacher[];
  sectionSchedules: SectionScheduleType[];
};
