import { Dispatch } from 'react';
import { UserData } from './Auth';

export enum UserActionTypes {
  RESET_STATE = 'RESET_STATE',
  SET_IS_LOGGED = 'SET_IS_LOGGED',
  SET_IS_SESSION_EXPIRED = 'SET_IS_SESSION_EXPIRED',
  SET_FULL_NAME = 'SET_FULL_NAME',
  SET_USER_DATA = 'SET_USER_DATA',
}

export type UserAction =
  | { type: UserActionTypes.RESET_STATE }
  | { type: UserActionTypes.SET_IS_LOGGED; payload: boolean }
  | { type: UserActionTypes.SET_IS_SESSION_EXPIRED; payload: boolean }
  | { type: UserActionTypes.SET_FULL_NAME; payload: string }
  | {
      type: UserActionTypes.SET_USER_DATA;
      payload: Partial<UserState['userData']> | UserState['userData'];
    };

export type UserState = {
  isLogged: boolean;
  isSessionExpired: boolean;
  userData?: UserData;
  isAuthorizedTo: (
    requiredPermissions: string[],
    allPermisionsRequired?: boolean,
  ) => boolean;
};

export type UserContextType = {
  state: UserState;
  dispatch: Dispatch<UserAction>;
};
