import { useParams } from 'react-router-dom';
import InterimDegreeStudentActivitiesResumen from './parts/InterimDegreeStudentActivitiesResumen';
import InterimDegreeStudentActivitiesLoader from './parts/InterimDegreeStudentActivitiesLoader';

const InterimDegreeProcessActivitiesResumen = () => {
  const { interimDegreeId } = useParams<{
    interimDegreeId: string;
  }>();

  return (
    <InterimDegreeStudentActivitiesLoader interimDegreeId={interimDegreeId}>
      {({ data }) => <InterimDegreeStudentActivitiesResumen summary={data} />}
    </InterimDegreeStudentActivitiesLoader>
  );
};

export default InterimDegreeProcessActivitiesResumen;
