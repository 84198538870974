import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card } from 'reactstrap';

import Base from '../../components/base/Base';
import CareerSelector from '../../components/careerSelector/CareerSelector';
import { CareerStatus } from '../../components/careerSelector/academicInformationTypes';
import { CareerSelectorConsumer } from '../../components/careerSelector/parts/CareerSelectorProvider';
import TopBar from '../../components/topBar/TopBar';
import GoToTuitionMsg from './parts/GoToTuitionMsg';
import TuitionContinuityLoader from './parts/TuitionContinuityLoader';

export default function TuitionContinuity() {
  const { t } = useTranslation();

  const history = useHistory();

  const goToTuition = useCallback(
    (studyPlanEnrollmentId: number) => {
      history.push(`tuition-continuity/tuition/step/${studyPlanEnrollmentId}`);
    },
    [history],
  );

  return (
    <Base>
      <TopBar title={t('tuitionContinuity.title')} />
      <Card className="p-4 mx-3">
        <CareerSelector allowedStatus={[CareerStatus.Regular]}>
          <CareerSelectorConsumer>
            {({ selectedCareer }) => (
              <TuitionContinuityLoader
                studyPlanEnrollmentId={selectedCareer.studyPlanEnrollmentId}
              >
                {({ data: studentStatus }) => (
                  <GoToTuitionMsg
                    {...studentStatus}
                    goToTuition={goToTuition}
                  />
                )}
              </TuitionContinuityLoader>
            )}
          </CareerSelectorConsumer>
        </CareerSelector>
      </Card>
    </Base>
  );
}
