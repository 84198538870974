import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import Base from '../../components/base/Base';
import { CareerStatus } from '../../components/careerSelector/academicInformationTypes';
import CareerSelector from '../../components/careerSelector/CareerSelector';
import { CareerSelectorConsumer } from '../../components/careerSelector/parts/CareerSelectorProvider';
import DisplayError from '../../components/info/DisplayError';
import TopBar from '../../components/topBar/TopBar';
import InterimDegreeStudentActivitiesList from './parts/InterimDegreeStudentActivitiesList';
import InterimDegreeStudentActivitiesLoader from './parts/InterimDegreeStudentActivitiesLoader';
import { SearchControls } from './parts/SearchControls/SearchControls';
import SearchControlLoader from './parts/SearchControls/SearchControlsLoader';

const dictPrefix = 'interimDegreeActivities';

const InterimDegreeActivities = () => {
  const { t } = useTranslation();

  const texts = useMemo(() => {
    return {
      title: t(`${dictPrefix}.title`),
      subtitle: t(`${dictPrefix}.subtitle`),
      description: t(`${dictPrefix}.description`),
      noActivities: t(`${dictPrefix}.noActivities`),
      regularInfo: {
        title: t(`${dictPrefix}.regularInfo.title`),
        body: t(`${dictPrefix}.regularInfo.body`),
      },
    };
  }, [t]);

  return (
    <Base>
      <TopBar title={texts.title} />
      <Card className="p-4 mx-3">
        <CareerSelector
          allowedStatus={[
            CareerStatus.Regular,
            CareerStatus.Graduate,
            CareerStatus.Titled,
          ]}
        >
          <Row>
            <Col>
              <h2 className="fs-20 fw-600 text-primary text-uppercase">
                {texts.subtitle}
              </h2>
              <p className="fs-16 text-medium mb-4">{texts.description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <CareerSelectorConsumer>
                {({ selectedCareer }) => (
                  <SearchControlLoader
                    studyPlanEnrollmentId={selectedCareer.studyPlanEnrollmentId}
                  >
                    {({ data }) => (
                      <SearchControls
                        dictPrefix={dictPrefix}
                        filters={{
                          ...data,
                        }}
                      >
                        {({ data: { interimDegreeId, hasFilters } }) => (
                          <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                            {!hasFilters ? (
                              <Col
                                md={12}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <DisplayError
                                  icon="information"
                                  title={texts.regularInfo.title}
                                  body={texts.regularInfo.body}
                                />
                              </Col>
                            ) : (
                              <Col md={12}>
                                <InterimDegreeStudentActivitiesLoader
                                  interimDegreeId={interimDegreeId}
                                >
                                  {({ data }) => (
                                    <InterimDegreeStudentActivitiesList
                                      summary={data}
                                    />
                                  )}
                                </InterimDegreeStudentActivitiesLoader>
                              </Col>
                            )}
                          </div>
                        )}
                      </SearchControls>
                    )}
                  </SearchControlLoader>
                )}
              </CareerSelectorConsumer>
            </Col>
          </Row>
        </CareerSelector>
      </Card>
    </Base>
  );
};

export default InterimDegreeActivities;
