import { Redirect, Route, Switch } from 'react-router-dom';
import routes, { PathsLayouts } from '../../config/routes';

type RoutesByLayoutProps = {
  pathLayout: PathsLayouts;
  defaultRootPath?: string;
};

/**
 * Es utilizado para renderizar todas las rutas asociadas a un layout
 */
const RoutesByLayout = ({
  pathLayout,
  defaultRootPath,
}: RoutesByLayoutProps): JSX.Element | null => {
  const routesByLayout = routes[pathLayout];
  const rootPath = defaultRootPath ? defaultRootPath : pathLayout;

  if (routesByLayout) {
    return (
      <Switch>
        {routesByLayout.views.map(({ path, component }, key) => {
          return (
            <Route
              path={pathLayout + path}
              component={component}
              key={key}
              exact
            />
          );
        })}

        <Redirect from="*" to={rootPath + routesByLayout.defaultPath} />
      </Switch>
    );
  }
  return null;
};

export default RoutesByLayout;
