import { Alert } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import Base from '../../../components/base/Base';
import { InterimDegreeAuthorizedStudentActivitiesDetails } from '../interfaces/interim-degree-authorized-student-activities-details.interface';
import ActivitiesResumenForm from './ActivitiesResumenForm';
import ActivitiesResumenTable from './ActivitiesResumenTable';

const dictPrefix = 'interimDegreeActivitiesDetails';

type InterimDegreeStudentActivitiesListProps = {
  summary?: InterimDegreeAuthorizedStudentActivitiesDetails;
};

const InterimDegreeStudentActivitiesList = ({
  summary,
}: InterimDegreeStudentActivitiesListProps) => {
  const { t } = useTranslation();

  return (
    <Base>
      <Row>
        <Col className="mb-4" xs={12} style={{ padding: '0px' }}>
          {!!summary?.activities?.length && (
            <ActivitiesResumenTable data={summary?.activities} />
          )}
          {!summary?.activities?.length && (
            <Alert
              className=""
              color="info"
              icon="information"
              size="lg"
              text={t(`${dictPrefix}.noActivities`)}
            />
          )}
        </Col>
        {!!summary?.degreeSummary?.degreeDate && (
          <ActivitiesResumenForm
            grade={summary?.degreeSummary?.degreeGrade ?? 'N/A'}
            date={summary?.degreeSummary?.degreeDate ?? undefined}
            interimDegreeAverage={summary?.interimDegreeAverage}
            distinctionRank={
              summary?.degreeSummary?.degreeDistinctionRank?.name
            }
          />
        )}
      </Row>
    </Base>
  );
};

export default InterimDegreeStudentActivitiesList;
