import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Container, Row } from 'reactstrap';

import Base from '../../components/base/Base';
import CareerSelector from '../../components/careerSelector/CareerSelector';
import { CareerStatus } from '../../components/careerSelector/academicInformationTypes';
import TopBar from '../../components/topBar/TopBar';
import { StudentSelectedSections } from '../../types/studentManagementTypes';
import CoursesInfo from './parts/CoursesInfo';
import ScheduleInfo from './parts/ScheduleInfo';

const StudentSchedule = () => {
  const prefix = 'studentManagement';
  const { t } = useTranslation();
  const [coursesInfo, setCoursesInfo] = useState<
    StudentSelectedSections | undefined
  >();

  return (
    <Base>
      <TopBar title={t(`${prefix}.schedule`)} />
      <Container fluid>
        <Card className="py-4 px-4">
          <CareerSelector allowedStatus={[CareerStatus.Regular]}>
            <Row>
              <Col xs={12}>
                <ScheduleInfo setCoursesInfo={setCoursesInfo} />
              </Col>
              <Col xs={12}>
                <CoursesInfo coursesInfo={coursesInfo} />
              </Col>
            </Row>
          </CareerSelector>
        </Card>
      </Container>
    </Base>
  );
};

export default StudentSchedule;
