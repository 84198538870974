import {
  TableCollapsable,
  Table as TableCore,
  useMobile,
} from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { DegreeProcessActivity, DegreeProcessRendition } from '../types';
import { useColumns } from './useColumns';
import { useColumnsRenditions } from './useColumnsRenditions';

export interface TableProps {
  data: DegreeProcessActivity[];
}

export default function Table({ data }: TableProps) {
  const { t } = useTranslation();

  const isMobile = useMobile();

  const columns = useColumns({ isMobile });
  const columnsRenditions = useColumnsRenditions();

  if (isMobile) {
    return <TableCore columns={columns} data={data} />;
  }

  return (
    <TableCollapsable<DegreeProcessActivity>
      columns={columns}
      secondColumn={{
        tdClassName: 'px-4',
        columnName: 'quota',
        headerText: t(`table.quota`),
        cellFormat: ({ row }) => {
          return (
            <TableCore<DegreeProcessRendition>
              borderless={false}
              color="transparent"
              striped
              columns={columnsRenditions}
              data={row.renditions}
            />
          );
        },
      }}
      data={data}
    />
  );
}
