import { Icon, Table } from '@octano/global-ui';
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { SelectedSection } from '../../types/courseSelectionTypes';

type SelectedSectionsProps = {
  mode?: 'VIEW' | 'EDIT';
  selectedSections: SelectedSection[];
  removeSection?: (id: number) => void;
};
const SelectedSections = ({
  mode = 'EDIT',
  selectedSections,
  removeSection,
}: SelectedSectionsProps) => {
  const prefix = 'selectedSections';
  const { t } = useTranslation();

  const columns: ColumnTable<SelectedSection>[] = useMemo(() => {
    const cols: ColumnTable<SelectedSection>[] = [
      {
        columnName: 'id',
        headerText: (
          <div className="text-center">{t(`${prefix}.courseCode`)}</div>
        ),
        cellFormat: (options) => (
          <div className="text-center text-uppercase">
            {options.row.course.shortening}
          </div>
        ),
      },
      {
        columnName: 'id',
        headerText: (
          <div className="text-center">{t(`${prefix}.courseName`)}</div>
        ),
        cellFormat: (options) => (
          <div className="text-center text-capitalize">
            {options.row.course.name}
          </div>
        ),
      },
      {
        columnName: 'name',
        headerText: (
          <div className="text-center">{t(`${prefix}.sectionName`)}</div>
        ),
        cellFormat: ({ row }) => (
          <div className="text-center text-uppercase">{row.name}</div>
        ),
      },
      {
        columnName: 'id',
        headerText: <div className="text-center">{t(`${prefix}.credits`)}</div>,
        cellFormat: (options) => (
          <div className="text-center">{options.row.course.credits}</div>
        ),
      },
      {
        columnName: 'id',
        headerText: <div className="text-center">{t(`${prefix}.type`)}</div>,
        cellFormat: (options) => (
          <div className="text-center text-capitalize">
            {options.row.course.types.map((type, i) =>
              i === 0 ? `${type.name} ` : ` - ${type.name}`,
            )}
          </div>
        ),
      },
    ];
    // La opción de borrar se muestra sólo en el modo de edición
    if (mode === 'EDIT' && removeSection) {
      cols.push({
        columnName: 'id',
        headerText: '',
        cellFormat: ({ row }) => (
          <div
            className="text-center text-light"
            onClick={() => removeSection(row.id)}
          >
            <Icon className="cursor-pointer" name="trash" color="inherit" />
          </div>
        ),
      });
    }
    return cols;
  }, [mode, t, removeSection]);

  return (
    <Row>
      <Col>
        <div className="bg-light p-4">
          <span className="text-uppercase text-primary fs-20 fw-700">
            {t(`${prefix}.title`)}
          </span>
          <div className="pt-3">
            {selectedSections.length > 0 ? (
              <Table
                color="gray"
                size="small"
                columns={columns}
                data={selectedSections}
              />
            ) : (
              <span className="fs-18 text-dark">
                {t(`${prefix}.infoSelect`)}
              </span>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SelectedSections;
