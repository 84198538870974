import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import Title from '../../components/text/Title';
import CreditSummary from './parts/CreditSummary';
import EnrollmentProcess from './parts/EnrollmentProcess';
import PeriodMinAndMaxCreditsCard from './parts/PeriodMinAndMaxCreditsCard';
import SelectedCredits from './parts/SelectedCredits';
import StudyPlanStudent from './parts/StudyPlanStudent';

const CourseRegistration = () => {
  const prefix = 'courseRegistration';
  const { t } = useTranslation();
  const [helpEmail, setHelpEmail] = useState<string>('');

  const onSetHelpEmail = useCallback(
    (email: string) => {
      setHelpEmail(email);
    },
    [setHelpEmail],
  );

  return (
    <>
      <Title text={t(`${prefix}.title`)} />
      <Card className="mt-3 py-4 px-3 px-md-5">
        <Row>
          {/* Card con detalle del alumno y carrera */}
          <Col xs={12} lg={5} className="py-1">
            <StudyPlanStudent />
          </Col>

          {/* Card con créditos de la carrera*/}
          <Col xs={12} lg={7} className="py-1">
            <Card className="bg-light px-5 py-4 h-100">
              <CreditSummary />
            </Card>
          </Col>
        </Row>
        <Row className="py-2">
          <Col xs={12} md={4}>
            {/* Ver horario sugerido (cursos obligatorios) */}
          </Col>
          <Col xs={12} md={4}>
            <PeriodMinAndMaxCreditsCard setEmailHelp={onSetHelpEmail} />
          </Col>
          <Col xs={12} md={4}>
            <SelectedCredits />
          </Col>
        </Row>
        <EnrollmentProcess helpEmail={helpEmail} />
      </Card>
    </>
  );
};

export default CourseRegistration;
