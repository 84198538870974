import { TextInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { CurricularProgressionResponse } from '../../../api/requests/curriculum';
import SectionTitle from '../../../components/text/SectionTitle';

interface CurriculumResumeProps {
  data?: CurricularProgressionResponse;
}

const CurriculumResume = ({ data }: CurriculumResumeProps) => {
  const prefix = 'studyProgression';
  const { t } = useTranslation();

  const hasMention = useMemo(() => {
    return !data?.isPrimary;
  }, [data]);

  const duration = useMemo(() => {
    if (data) {
      return `${data.duration} ${
        data.duration === 1
          ? data.studyPlanVersion.studyPlan.periodType.name
          : data.studyPlanVersion.studyPlan.periodType.namePlural
      }`;
    }
    return '';
  }, [data]);

  return (
    <>
      <Row>
        <Col xs={12} className="pb-3">
          <SectionTitle text={t(`${prefix}.resume.title`)} />
        </Col>
      </Row>
      <Row>
        {hasMention && (
          <>
            <Col xs={12} sm={6} md={4} xl={4}>
              <TextInput
                name="mentionCode"
                label={t(`${prefix}.resume.mentionCode`)}
                value={data?.code}
                disabled
              />
            </Col>
            <Col xs={12} sm={6} md={4} xl={4}>
              <TextInput
                name="mentionName"
                label={t(`${prefix}.resume.mentionName`)}
                value={data?.name}
                disabled
              />
            </Col>
          </>
        )}
        <Col xs={12} sm={6} md={4} xl={hasMention ? 4 : ''}>
          <TextInput
            name="duration"
            label={t(`${prefix}.resume.duration`)}
            value={duration}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={4} xl="">
          <TextInput
            name="totalCredits"
            label={t(`${prefix}.resume.totalCredits`)}
            value={data?.totalCredits.toString()}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={4} xl="">
          <TextInput
            name="obligatory"
            label={t(`${prefix}.resume.obligatory`)}
            value={data?.obligatoryCredits.toString()}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={4} xl="">
          <TextInput
            name="optional"
            label={t(`${prefix}.resume.optional`)}
            value={data?.optionalCredits.toString()}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={4} xl="">
          <TextInput
            name="others"
            label={t(`${prefix}.resume.others`)}
            value={data?.otherCredits.toString()}
            disabled
          />
        </Col>
        <Col xs={12} sm={6} md={4} xl="">
          <TextInput
            name="nActivities"
            label={t(`${prefix}.resume.nActivities`)}
            value={data?.activityNumber.toString()}
            disabled
          />
        </Col>
      </Row>
    </>
  );
};

export default CurriculumResume;
