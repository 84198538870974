import { Box } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface StudyPlanBoxProps {
  studyPlan: string;
  mention?: string | null;
}

const StudyPlanBox = ({ studyPlan, mention }: StudyPlanBoxProps) => {
  const { t } = useTranslation();

  return (
    <Box
      className="float-right"
      style={{ borderRadius: 4 }}
      body={
        <Row style={{ width: 'clamp(fit-content, 250px, 210px)' }}>
          <Col sm={12}>
            <span className="text-uppercase fw-600">
              {t(`common.terms.studyPlanName`)}
            </span>
            <br />
            <span>{studyPlan}</span>
          </Col>
          {mention && (
            <Col xs={12} sm={6}>
              <span className="text-uppercase fw-600">
                {t(`common.terms.mention`)}
              </span>
              <br />
              <span>{mention}</span>
            </Col>
          )}
        </Row>
      }
      color="secondary"
      variant="outlined"
    />
  );
};

export default StudyPlanBox;
