import React, { Dispatch } from 'react';

export enum StepActionType {
  NEXT_STEP = 'NEXT_STEP',
  PREV_STEP = 'PREV_STEP',
  SET_STEP = 'SET_STEP',
}

export type StepAction =
  | { type: StepActionType.NEXT_STEP }
  | { type: StepActionType.PREV_STEP }
  | { type: StepActionType.SET_STEP; payload: number };

export type StepState = {
  currentStep: number;
};

export type StepContextType = {
  state: StepState;
  dispatch: Dispatch<StepAction>;
};

export interface CurrentStep {
  currentStep: {
    id: number;
    step: TuitionContinuitySteps;
  };
}

export enum TuitionContinuitySteps {
  STUDENT_FILES = 'student-file',
  SUSTAINER = 'sustainer',
  PAYMENT = 'payment',
  SIGN_DOCUMENTATION = 'sign-documentation',
  COMPLETED = 'completed',
}

export interface StepInformation {
  id: string;
  name: string;
  component: React.FunctionComponent;
}

export interface CreateStepsProps {
  translationPrefix: string;
  allAvailableSteps: {
    id: string;
    component: React.FunctionComponent;
  }[];
}
