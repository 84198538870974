import { Card, Col, Row } from 'reactstrap';
import { Alert } from '@octano/global-ui';

import Base from '../../../components/base/Base';
import TopBar from '../../../components/topBar/TopBar';
import { useTranslation } from 'react-i18next';
import InterimDegressAuthorizedStudentBox from './InterimDegressAuthorizedStudentBox';
import { InterimDegreeAuthorizedStudentActivitiesDetails } from '../interfaces/interim-degree-authorized-student-activities-details.interface';
import ActivitiesResumenTable from './ActivitiesResumenTable';
import ActivitiesResumenForm from './ActivitiesResumenForm';

const dictPrefix = 'interimDegreeActivitiesDetails';

type InterimDegreeStudentActivitiesResumenProps = {
  summary?: InterimDegreeAuthorizedStudentActivitiesDetails;
};

const InterimDegreeStudentActivitiesResumen = ({
  summary,
}: InterimDegreeStudentActivitiesResumenProps) => {
  const { t } = useTranslation();

  return (
    <Base>
      <TopBar title={t(`${dictPrefix}.navTitle`)} />
      <Card className="p-4 mx-3">
        <Row>
          <Col className="mb-4" xs={12}>
            <div className="d-flex w-100 align-items-start justify-content-center">
              <div className="flex-fill">
                <p className="fs-16">{t(`${dictPrefix}.description`)}</p>
              </div>
              {!!summary?.summaryBox && (
                <InterimDegressAuthorizedStudentBox {...summary?.summaryBox} />
              )}
            </div>
          </Col>

          <Col className="mb-4" xs={12}>
            {!!summary?.activities?.length && (
              <ActivitiesResumenTable data={summary?.activities} />
            )}
            {!summary?.activities?.length && (
              <Alert
                className=""
                color="info"
                icon="information"
                size="lg"
                text={t(`${dictPrefix}.noActivities`)}
              />
            )}
          </Col>
          {!!summary?.degreeSummary?.degreeDate && (
            <ActivitiesResumenForm
              grade={summary?.degreeSummary?.degreeGrade ?? 'N/A'}
              date={summary?.degreeSummary?.degreeDate ?? undefined}
              interimDegreeAverage={summary?.interimDegreeAverage}
              distinctionRank={
                summary?.degreeSummary?.degreeDistinctionRank?.name
              }
            />
          )}
        </Row>
      </Card>
    </Base>
  );
};

export default InterimDegreeStudentActivitiesResumen;
