import { Button } from '@octano/global-ui';

type GoToEnrrollmentMsgProps = {
  title: string;
  body: string | JSX.Element;
  actionButton?: { text: string; action: () => void };
};

export default function GoToEnrollmentMsg({
  title,
  body,
  actionButton,
}: GoToEnrrollmentMsgProps) {
  return (
    <div
      className="d-flex align-items-center justify-content-center text-center"
      style={{ background: '#f7f7f7', minHeight: 333 }}
    >
      <div className="m-2">
        <h2 className="fs-20 fw-600 text-primary">{title}</h2>
        <p
          className="fs-18 lh-30 text-light mt-4 mb-5"
          style={{ maxWidth: 390 }}
        >
          {body}
        </p>
        {actionButton && (
          <Button text={actionButton.text} onClick={actionButton.action} />
        )}
      </div>
    </div>
  );
}
