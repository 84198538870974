import { Box, Button, Icon, addToast } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import {
  getCourseHistoryPdf,
  getCourseHistoryRequest,
} from '../../../api/requests/curriculum';
import { useAcademicInformation } from '../../../components/careerSelector/useAcademicInformation';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';
import {
  CourseHistoryPeriod,
  CourseValidatedHistorySection,
} from '../../../types/curricularAdvancementTypes';
import { downloadFromBlob } from '../../../utils/blob';
import CourseHistoryCollapse, {
  ICourseHistoryAccordeonProps,
} from './CourseHistoryCollapse';

export function CourseHistory() {
  const { selectedCareer } = useAcademicInformation();

  const [period, setPeriod] = useState<CourseHistoryPeriod[]>([]);
  const [coursesValidated, setCoursesValidated] = useState<
    CourseValidatedHistorySection[]
  >([]);
  const [generalAverage, setGeneralAverage] = useState<number | null>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  /**
   * Peticion que trae los datos del tab del Historial de cursos
   */
  const getCourseHistory = useCallback(async () => {
    const { data, error } = await getCourseHistoryRequest(
      selectedCareer.studyPlanEnrollmentId,
    );
    if (data) {
      setPeriod(data.data.periods);
      setCoursesValidated(data.data.coursesValidated);
      setGeneralAverage(data.data.generalAvg);
      setErrorLoading(undefined);
    }
    if (error) {
      setErrorLoading(error.code);
    }
    setLoading(false);
  }, [selectedCareer.studyPlanEnrollmentId, setErrorLoading, setLoading]);

  /**
   * Petición para descargar el pdf del historico de cursos
   */
  const downloadPDF = useCallback(async () => {
    setButtonLoading(true);
    const { data, error } = await getCourseHistoryPdf(
      selectedCareer.studyPlanEnrollmentId,
    );
    if (error) {
      addToast({
        color: 'danger',
        icon: 'error',
        text: t('curricularAdvancement.courseHistory.downloadFailed'),
      });
    }
    if (data) {
      addToast({
        color: 'success',
        icon: 'check',
        text: t('curricularAdvancement.courseHistory.downloadSuccess'),
      });
      const name = `historial_${dayjs().format('YYYY-MM-DD')}`;
      downloadFromBlob(data, name);
    }
    setButtonLoading(false);
  }, [t, selectedCareer.studyPlanEnrollmentId]);

  useEffect(() => {
    getCourseHistory();
  }, [getCourseHistory]);

  const periodCourses = useMemo(() => {
    const courses: Array<
      ICourseHistoryAccordeonProps & { key: string | number }
    > = [];

    if (coursesValidated.length > 0) {
      courses.push({
        key: 'courses_validated',
        title: t('curricularAdvancement.courseHistory.coursesValidated'),
        courses: coursesValidated,
      });
    }
    for (const p of period) {
      courses.push({
        key: p.periodId,
        title: p.periodName,
        average: p.periodAvg,
        courses: p.sections,
      });
    }
    return courses;
  }, [period, coursesValidated, t]);

  if (loading) {
    return <Loading insideCard />;
  }

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        body={errorLoading}
        retryAction={() => getCourseHistory()}
        loadingAction={loading}
      />
    );
  }

  return (
    <div className="pt-5">
      {periodCourses.length === 0 && (
        <div className="d-flex flex-column align-items-center justify-content-center py-5">
          <Icon name="information" size={55} />
          <p className="fs-20 text-dark mt-4">
            {t('curricularAdvancement.courseHistory.noCourses')}
          </p>
        </div>
      )}

      {periodCourses.length > 0 && (
        <>
          <Row className="align-items-center pb-4">
            <Col>
              <Box
                variant="standard"
                color="secondary"
                title={t(
                  'curricularAdvancement.courseHistory.generalPassedAverage',
                )}
                body={generalAverage !== null ? String(generalAverage) : ''}
              />
            </Col>
            <Col className="text-right">
              <div className="py-4">
                <Button
                  icon="download"
                  onClick={downloadPDF}
                  outlined
                  size="md"
                  style={{ width: '223px' }}
                  loading={buttonLoading}
                  text={t(
                    'curricularAdvancement.courseHistory.downloadHistory',
                  )}
                />
              </div>
            </Col>
          </Row>
          {periodCourses.map((course) => (
            <CourseHistoryCollapse
              key={course.key}
              title={course.title}
              average={course.average}
              courses={course.courses}
            />
          ))}
        </>
      )}
    </div>
  );
}
