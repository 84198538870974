import { Button, ColumnTable } from '@octano/global-ui';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../utils/dates';
import BadgeState from '../../degreeProcesses/parts/BadgeState';
import { InterimDegreeActivity } from '../interfaces/interim-degree-activity.interface';
import { InterimDegreeStudentActivity } from '../interfaces/interim-degree-student-activity.interface';

interface Props {
  openAddNewRecord?: (interimDegreeActivity: InterimDegreeActivity) => void;
}

const prefix = 'interimDegreeActivitiesDetails';

export function useActivitiesResumenColumnsRedentions({
  openAddNewRecord,
}: Props) {
  const { t } = useTranslation();

  const handleOpenAddNewRecord = useCallback(
    (row: any) => {
      if (row?.interimDegreeActivity) {
        !!openAddNewRecord && openAddNewRecord(row?.interimDegreeActivity);
      }
    },
    [openAddNewRecord],
  );

  const columns: ColumnTable<
    InterimDegreeStudentActivity & { buildEmptyRow?: any }
  >[] = useMemo(() => {
    const columnWidth = `${100 / 7}%`;
    return [
      {
        columnName: 'position',
        headerText: t(`${prefix}.table.index`),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row, value }) =>
          row?.buildEmptyRow ? <></> : value + 1,
      },
      {
        columnName: 'date',
        headerText: t(`${prefix}.table.date`),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ value, row }) =>
          row?.buildEmptyRow ? <></> : formatDate(value, 'DD/MM/YYYY'),
      },
      {
        columnName: 'status',
        headerText: t(`${prefix}.table.status`),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row, value }) => {
          if (row?.buildEmptyRow) return <></>;
          return (
            <BadgeState
              status={value}
              text={t(`${prefix}.statuses.${value}`)}
            />
          );
        },
      },
      {
        columnName: 'grade',
        headerText: t(`${prefix}.table.grade`),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row, value }) =>
          row?.buildEmptyRow ? <></> : value ?? 'N/A',
      },
      {
        columnName: 'maxGrade',
        headerText: t(`${prefix}.table.maxGrade`),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row, value }) =>
          row?.buildEmptyRow ? <></> : value ?? 'N/A',
      },
      {
        columnName: 'comments',
        headerText: t(`${prefix}.table.comments`),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row, value }) =>
          row?.buildEmptyRow ? <></> : value ?? '-',
      },
      {
        columnName: 'editor',
        headerText: t(`${prefix}.table.editor`),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row, value }) =>
          row?.buildEmptyRow ? <></> : value?.fullName,
      },
      {
        columnName: 'buildEmptyRow',
        headerText: '',
        tdClassName: 'text-center td-build-empty-row',
        thClassName: 'text-center',
        width: columnWidth,
        cellFormat: ({ row }) => {
          if (row?.buildEmptyRow) {
            return (
              <>
                {row.interimDegreeActivityId !== 0 ? (
                  <Button
                    className="td-btn-action"
                    text={t(`${prefix}.actions.addNewRecord`)}
                    onClick={() => {
                      handleOpenAddNewRecord(row);
                    }}
                  />
                ) : (
                  <Button
                    className="td-btn-action"
                    text={t(`${prefix}.actions.addNewRecord`)}
                    disabled={true}
                  />
                )}
              </>
            );
          }

          return <></>;
        },
      },
    ];
  }, [t, handleOpenAddNewRecord]);

  return columns;
}
