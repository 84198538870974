import { useTranslation } from 'react-i18next';

import DisplayError from '../../../components/info/DisplayError';

export default function AttendanceReqError() {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center align-items-center mt-4">
      <DisplayError
        title={t(`common.displayError.title`)}
        body={t(`common.displayError.body`)}
      />
    </div>
  );
}
