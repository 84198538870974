import { Button, addToast, useMobile } from '@octano/global-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { downloadFromURL } from '../../../../utils/blob';
import { ReportType } from '../../types';
import GeneratingFileModal from '../GeneratingFileModal';
import NotRegularStModal from '../NotRegularStModal';
import { createReportDownloadHandler } from './handlerCreator';

enum ModalType {
  NONE,
  GENERATING_FILE,
  NOT_REGULAR_STUDENT,
}

interface IRegularStudentActionsProps {
  studyPlanEnrollmentId: number;
  reportType: ReportType;
}

export default function RegularStudentActions({
  studyPlanEnrollmentId,
  reportType,
}: IRegularStudentActionsProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [openedModal, setOpenedModal] = useState<ModalType>();

  const handleReportDownload = createReportDownloadHandler({
    beforeDownload: () => setOpenedModal(ModalType.GENERATING_FILE),
    onSuccess: ({ url }) => {
      if (url) {
        downloadFromURL(url);
      }
      setOpenedModal(ModalType.NONE);
    },
    onError: ({ data }) => {
      if (data?.error === 'NOT_REGULAR_STUDENT') {
        setOpenedModal(ModalType.NOT_REGULAR_STUDENT);
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`reports.errors.errorGeneratingFile`),
        });
        setOpenedModal(ModalType.NONE);
      }
    },
  });

  return (
    <>
      <NotRegularStModal
        isOpen={openedModal === ModalType.NOT_REGULAR_STUDENT}
      />

      <GeneratingFileModal isOpen={openedModal === ModalType.GENERATING_FILE} />
      <div
        className="d-flex flex-wrap align-items-center justify-content-center "
        style={{ gap: 12 }}
      >
        <Button
          text={t(`common.actions.download`)}
          icon="download"
          size="sm"
          outlined
          style={{ width: isMobile ? '90%' : 123 }}
          onClick={() =>
            handleReportDownload(studyPlanEnrollmentId, reportType)
          }
        />
      </div>
    </>
  );
}
