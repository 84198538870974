import { useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { AttendanceStatus } from '../../../types/attendance';
import StatusBox from './StatusBox';

export default function StatusesLegend() {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <div
      className={`d-flex flex-wrap ${
        isMobile ? 'justify-content-between' : ''
      }`}
      style={{ gap: isMobile ? 12 : 14 }}
    >
      <div className="d-flex align-items-center">
        <StatusBox
          status={AttendanceStatus.PRESENT}
          size={isMobile ? 31 : 36}
        />
        <p
          className={`text-primary text-uppercase mb-0 ${
            isMobile ? 'fs-12 ml-2' : 'fs-14 ml-3'
          }`}
        >
          {t(`common.terms.${AttendanceStatus.PRESENT}`)}
        </p>
      </div>
      <div className="d-flex align-items-center">
        <StatusBox status={AttendanceStatus.ABSENT} size={isMobile ? 31 : 36} />
        <p
          className={`text-primary text-uppercase mb-0 ${
            isMobile ? 'fs-12 ml-2' : 'fs-14 ml-3'
          }`}
        >
          {t(`common.terms.${AttendanceStatus.ABSENT}`)}
        </p>
      </div>
      <div className="d-flex align-items-center">
        <StatusBox
          status={AttendanceStatus.JUSTIFIED}
          size={isMobile ? 31 : 36}
        />
        <p
          className={`text-primary text-uppercase  mb-0 ${
            isMobile ? 'fs-12 ml-2' : 'fs-14 ml-3'
          }`}
        >
          {t(`common.terms.${AttendanceStatus.JUSTIFIED}`)}
        </p>
      </div>
    </div>
  );
}
