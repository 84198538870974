/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Icon, SearchBox, Table } from '@octano/global-ui';
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { deburr } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  requestCoursesFilter,
  requestSectionsFilter,
} from '../../../api/requests/courseSearching';
import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';
import { useDataFilter } from '../../../hooks/useDataFilter';
import { CourseType } from '../../../types/courseRegistrationRequestTypes';
import { SectionType } from '../../../types/sectionType';
import HeaderSectionList from '../../courseRegistration/parts/HeaderSectionList';
import { useSearchingCoursesState } from './../../../hooks/useSearchingCoursesState';
import { StudyPlanType } from './../../../types/courseSearchingTypes';
import SectionBox from './SectionBox';

type FilteredTableProps = {
  tableLoading: boolean;
  setTableLoading: Dispatch<SetStateAction<boolean>>;
  tableError: boolean;
  setTableError: Dispatch<SetStateAction<boolean>>;
  currentSemester: string | undefined;
  currentTeacher: string | undefined;
};

const FilteredTable = ({
  tableLoading,
  setTableLoading,
  tableError,
  setTableError,
  currentSemester,
  currentTeacher,
}: FilteredTableProps) => {
  const SEMENT_NUMBER = 10;
  const prefix = 'courseSearching.filteredTable';
  const { t } = useTranslation();

  const {
    studyPlans,
    courses,
    setCourses,
    sections,
    setSections,
    getSegmentedList,
  } = useSearchingCoursesState();

  const { setCourseState } = useCourseSelectionState();

  const goBackToCourses = () => {
    setCompleteList(courses);
    setList(getSegmentedList(courses));
    setColumns(coursesColumns);
    setTableStyles('courses');
  };

  const goBackToStudyPlans = () => {
    setCompleteList(studyPlans);
    setList(getSegmentedList(studyPlans));
    setColumns(studyPlanColumns);
    setTableStyles('study-plans');
  };

  const studyPlanColumns: ColumnTable<StudyPlanType>[] = [
    {
      columnName: 'name',
      headerText: t(`${prefix}.studyPlan`),
      cellFormat: ({ row }) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            searchCoursesByStudyPlan(row.curriculumId);
          }}
        >
          <span>
            {!row.curriculumIsPrimary
              ? row.name + ' - ' + row.versionCode + ' - ' + row.curriculumName
              : row.showPlanNameOnly
              ? row.name
              : row.name + ' - ' + row.versionCode}
          </span>
          <Icon name="chevron_right" className="chevron-icon" />
        </div>
      ),
    },
  ];

  const coursesColumns: ColumnTable<CourseType>[] = [
    {
      columnName: '',
      headerText: (
        <div
          className="cursor-pointer align-items-center"
          onClick={goBackToStudyPlans}
          style={{ width: 'fit-content' }}
        >
          <Icon
            name="chevron_left"
            className="cevron-left"
            color="inherit"
            size={12}
          />
          <span className="pl-2 go-back">{t(`${prefix}.goBack`)}</span>
        </div>
      ),
    },
    {
      columnName: 'shortening',
      headerText: t(`${prefix}.code`),
    },
    {
      columnName: 'name',
      headerText: t(`${prefix}.name`),
      cellFormat: ({ row }) => (
        <div
          className="text-uppercase cursor-pointer"
          onClick={() => {
            setSections(undefined);
            searchSectionsByCourse(row.id);
          }}
        >
          <span>{row.name}</span>
          <br />
          <span className="fs-12 text-light">
            {row.types?.map((type, i) =>
              i === 0 ? `${type.name} ` : ` - ${type.name}`,
            )}
          </span>
        </div>
      ),
    },
    {
      columnName: 'credits',
      headerText: t(`${prefix}.credits`),
    },
    {
      columnName: 'id',
      headerText: '',
      cellFormat: ({ row }) => (
        <div
          className="cursor-pointer"
          onClick={() => {
            setCourseState(row);
            setSections(undefined);
            searchSectionsByCourse(row.id);
          }}
        >
          <Icon name="chevron_right" />
        </div>
      ),
    },
  ];

  const sectionsColumns: ColumnTable<SectionType>[] = [
    {
      columnName: 'id',
      headerText: <HeaderSectionList goBackAction={goBackToCourses} />,
      cellFormat: (options) => {
        return <SectionBox options={options} />;
      },
    },
  ];

  const [list, setList] = useState<any[] | undefined>([]);
  const [completeList, setCompleteList] = useState<any[] | undefined>([]);
  const [columns, setColumns] = useState<ColumnTable<any>[]>(coursesColumns);
  const [segmentToShow, setSegmentToShow] = useState<number>(SEMENT_NUMBER);
  const [tableStyles, setTableStyles] = useState<string>('');

  const searchCoursesByStudyPlan = useCallback(
    async (curriculumId: number) => {
      setSections(undefined);
      setTableLoading(true);

      const { data, error } = await requestCoursesFilter(
        curriculumId,
        currentSemester,
        currentTeacher,
      );

      if (data) {
        setCourses(data.data);
        setTableLoading(false);
      } else if (error) {
        setCourses([]);
        setTableLoading(false);
        setTableError(true);
        // TODO: que mensaje se desplegara al existir un error?
      }
    },
    [
      setCourses,
      setSections,
      setTableError,
      setTableLoading,
      currentSemester,
      currentTeacher,
    ],
  );

  const searchSectionsByCourse = useCallback(
    async (courseId: number) => {
      setTableLoading(true);
      const { data, error } = await requestSectionsFilter(
        courseId,
        currentTeacher,
      );

      if (data) {
        setSections(data.data);
        setTableLoading(false);
      } else if (error) {
        setSections([]);
        setTableLoading(false);
        setTableError(true);
        // TODO: que mensaje se desplegara al existir un error?
      }
    },
    [setSections, currentTeacher, setTableError, setTableLoading],
  );

  const showMore = () => {
    setList(getSegmentedList(completeList, segmentToShow + SEMENT_NUMBER));
    setSegmentToShow((prev: number) => prev + SEMENT_NUMBER);
  };

  const filterFunction = useCallback(
    (row: CourseType, text: string) =>
      deburr(row.name).toUpperCase().includes(deburr(text).toUpperCase()) ||
      deburr(row.shortening).toUpperCase().includes(deburr(text).toUpperCase()),
    [],
  );
  const {
    results,
    totalResults,
    searchText,
    setSearchText,
    setPage,
  } = useDataFilter<CourseType>(courses || [], filterFunction, SEMENT_NUMBER);

  useEffect(() => {
    setCourses(undefined);
    setSections(undefined);
    setSearchText('');
    if (studyPlans && studyPlans.length > 0) {
      setCompleteList(studyPlans);
      setList(getSegmentedList(studyPlans));
      setSegmentToShow(SEMENT_NUMBER);
      setColumns(studyPlanColumns);
      setTableStyles('study-plans');
    } else {
      setList([]);
      setCompleteList([]);
      setSegmentToShow(SEMENT_NUMBER);
      setColumns(studyPlanColumns);
      setTableStyles('study-plans');
    }
  }, [studyPlans, setCourses, setSections, setSearchText]);

  useEffect(() => {
    setSections(undefined);
    setSearchText('');
    if (courses && courses.length > 0) {
      setCompleteList(courses);
      setList(getSegmentedList(courses));
      setSegmentToShow(SEMENT_NUMBER);
      setColumns(coursesColumns);
      setTableStyles('courses');
    } else {
      setList([]);
      setCompleteList([]);
      setSegmentToShow(SEMENT_NUMBER);
      setColumns(studyPlanColumns);
      setTableStyles('study-plans');
    }
  }, [courses, setSearchText, setSections]);

  useEffect(() => {
    if (sections && sections.length > 0) {
      setCompleteList(sections);
      setList(getSegmentedList(sections));
      setSegmentToShow(SEMENT_NUMBER);
      setColumns(sectionsColumns);
      setTableStyles('sections');
    } else {
      setList([]);
      setCompleteList([]);
      setSegmentToShow(SEMENT_NUMBER);
      setColumns(studyPlanColumns);
      setTableStyles('study-plans');
    }
  }, [sections]);

  useEffect(() => {
    if (tableStyles === 'courses') {
      setList(results);
      if (searchText.length < 3) {
        setCompleteList(courses);
      } else {
        setCompleteList(results);
      }
    }
  }, [results, setList, setCompleteList]);

  return (
    <div className={`filtered-table ${tableStyles}`}>
      <Table
        borderless={false}
        striped={false}
        height={700}
        columns={columns}
        data={list || []}
        isLoadingResults={tableLoading}
        subHeader={
          tableStyles === 'courses' ? (
            <SearchBox
              name="search_course"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={t(`${prefix}.searchPlaceholder`)}
            />
          ) : undefined
        }
        noResultsText={
          <div className="no-courses-found">
            <Icon
              name={tableError ? 'warning' : 'information'}
              className="information-icon"
              size={50}
            />
            <br />
            <br />
            <span className="no-filter-title">
              {tableError
                ? t(`${prefix}.error`)
                : sections
                ? t(`${prefix}.noSectionsFound`)
                : courses
                ? t(`${prefix}.noCoursesFound`)
                : studyPlans
                ? t(`${prefix}.noDataFound`)
                : t(`${prefix}.noFilter`)}
            </span>
            <br />
            <span className="no-filter-desc">
              {tableError
                ? t(`${prefix}.errorDesc`)
                : sections || courses || studyPlans
                ? ''
                : t(`${prefix}.noFilterDescription`)}
            </span>
          </div>
        }
      />
      {!tableLoading && completeList && completeList.length > segmentToShow && (
        <div className="pt-2">
          <Button
            text={t(`${prefix}.loadMore`)}
            size="md"
            onClick={() => showMore()}
            outlined
            fullwidth
          />
        </div>
      )}
    </div>
  );
};

export default FilteredTable;
