import { Alert } from '@octano/global-ui';

import { DegreeProcessActivity, DegreeProcessSummary } from '../types';
import FormView from './FormView';
import Table from './Table';

export interface DegreeProcessProps {
  summary?: DegreeProcessSummary;
  activities: DegreeProcessActivity[];
  texts: { noActivities: string };
}

export default function DegreeProcess({
  summary,
  activities,
  texts,
}: DegreeProcessProps) {
  return (
    <>
      <div className="mb-4">
        {Boolean(activities.length) && (
          <>
            <p className="text-right">
              Las siglas N/A significan que no aplica nota
            </p>
            <Table data={activities} />
          </>
        )}
        {!activities.length && (
          <Alert
            className=""
            color="info"
            icon="information"
            size="lg"
            text={texts.noActivities}
          />
        )}
      </div>
      {!!summary && (
        <FormView
          date={summary.date}
          graduationGrade={summary.gradeGraduation}
          degreeGrade={summary.gradeDegreeProcess}
          distinctionRank={summary.distinctionRank?.name}
        />
      )}
    </>
  );
}
