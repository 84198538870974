import { ReactElement, useCallback } from 'react';

import { createFetchContext } from '../contexts/FetchContextFactory';
import { getBase } from './requests';
import { BaseResponse } from './types';

export interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  BaseResponse
>();

export function useBaseLoader() {
  return useFetch();
}

export const BaseLoaderConsumer = FetchConsumer;

export default function BaseLoader({ children }: BaseLoaderProps) {
  const request = useCallback(() => {
    return getBase();
  }, []);

  return (
    <FetchProvider
      request={request}
      defaultQuery={undefined}
      fetchImmediately
      children={children}
    />
  );
}
