import dayjs from 'dayjs';

import { ModuleType } from '../types/sectionType';

export function formatTime(time: string) {
  return time.length === 8 ? time.slice(0, 5) : time;
}

// Función para mostrar la hora de inicio y fin de una clase
// Ej: 10:00 - 12:00
export function formatClassSchedule(
  startModule: ModuleType,
  endModule: ModuleType | null,
) {
  return `${formatTime(startModule.startTime)} -
    ${formatTime(endModule ? endModule.endTime : startModule.endTime)}`;
}

/**
 * Función que formatea una fecha en distintos formatos disponibles.
 * @param date Fecha ha formatear
 * @param format "DD-MM-YYYY" | "DD/MM/YYYY" | "YYYY-MM-DD" (Default: "DD-MM-YYYY")
 * @returns
 */
export function formatDate(
  date: string | Date,
  format: 'DD-MM-YYYY' | 'DD/MM/YYYY' | 'YYYY-MM-DD' = 'DD-MM-YYYY',
): string {
  if (dayjs(date).isValid()) {
    return dayjs.utc(date).format(format);
  } else {
    return '';
  }
}
