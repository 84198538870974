import {
  Button,
  Icon,
  Modal,
  Navbar,
  NavbarMenuOptionType,
} from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { ReactComponent as AddemicProductLogo } from '../assets/addemic/logo_expanded_white.svg';
import ConfirmDiscardModal from '../components/courseEnrollment/ConfirmDiscardModal';
import { IS_ADDEMIC } from '../config/constants';
import { PathsLayouts } from '../config/routes';
import { useCourseSelectionState } from '../hooks/useCourseSelectionState';
import { useUserState } from '../hooks/useUserState';
import CoursesEnrollmentContentLayout from './CoursesEnrollmentContentLayout';

const CoursesEnrollmentLayout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasAlreadyEnrrolled = useRouteMatch(
    `/enrollment/:studyPlanEnrollmentId/detail/:id`,
  );

  const { isLogged, resetUserState, isSessionExpired } = useUserState();
  const { selectedSections, resetCourseSelection } = useCourseSelectionState();

  const [isDiscardingChanges, setIsDiscardingChanges] = useState(false);

  const logout = useCallback(() => {
    resetCourseSelection();
    resetUserState();
  }, [resetCourseSelection, resetUserState]);

  const discardChanges = () => {
    resetCourseSelection();
    history.push('/');
  };

  const handleGoBackToPortal = () => {
    if (selectedSections?.length > 0) {
      setIsDiscardingChanges(true);
    } else {
      history.push('/');
    }
  };

  const menuOptions: NavbarMenuOptionType[] = [
    {
      icon: 'back',
      name: t(`courseRegistration.goBackToPortal`),
      action: handleGoBackToPortal,
    },
  ];

  if (!isLogged) {
    return <Redirect to={PathsLayouts.auth} />;
  }

  return (
    <>
      <Navbar
        Logo={() => (
          <div className="cursor-pointer" onClick={() => history.push('/')}>
            {IS_ADDEMIC ? (
              <AddemicProductLogo style={{ width: 160 }} />
            ) : (
              <img
                src="/tenant/logo_expanded_white.svg"
                alt="tenant_logo"
                style={{ maxHeight: 90 }}
              />
            )}
          </div>
        )}
        menuOptions={hasAlreadyEnrrolled ? [] : menuOptions}
      />

      <div className="px-2 px-md-4">
        <Container className="pt-4 pb-5" fluid>
          <CoursesEnrollmentContentLayout />
        </Container>
      </div>

      <ConfirmDiscardModal
        isOpen={isDiscardingChanges}
        onConfirm={discardChanges}
        onCancel={() => setIsDiscardingChanges(false)}
      />

      <Modal isOpen={isSessionExpired} toggle={logout} closeOnBackdrop={false}>
        <Row className="text-center">
          <Col xs={12} className="pt-2">
            <Icon name="alert" size="56px" color="secondary" />
          </Col>
          <Col xs={12} className="pt-4 pb-3">
            <span className="fs-22 text-dark">{t(`sessionExpired.title`)}</span>
          </Col>
          <Col xs={12}>
            <p className="fs-18 fw-300">{t(`sessionExpired.body`)}</p>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col xs={12} sm={10} className="pb-2 px-3 px-sm-2 mx-auto">
            <Button
              onClick={logout}
              text={t(`sessionExpired.button`)}
              fullwidth
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CoursesEnrollmentLayout;
