import { IconPaths } from '@octano/global-ui';

export enum DocumentTypesEnum {
  contract = 'contract',
  lem = 'lem',
  nem = 'nem',
  'sustainer-income-one' = 'sustainer-income-one',
  'sustainer-income-others' = 'sustainer-income-others',
  'promissory-note' = 'promissory-note',
  insurance = 'insurance',
}
export enum IdentityTypesEnum {
  'student-identity-card' = 'student-identity-card',
  'sustainer-identity-card' = 'sustainer-identity-card',
  'cosigner-identity-card' = 'cosigner-identity-card',
}
export const GeneralTypesEnum = {
  ...DocumentTypesEnum,
  ...IdentityTypesEnum,
};

export type IdentityTypes = keyof typeof IdentityTypesEnum;
export type DocumentTypes = keyof typeof DocumentTypesEnum;
export type GeneralTypes = keyof typeof GeneralTypesEnum;
export type ModalDocumentType = {
  [key in keyof typeof GeneralTypesEnum]: {
    name: string;
    hasTooltip?: boolean;
    icon: keyof typeof IconPaths;
  };
};

export function isDocumentType(
  input: GeneralTypes | string,
): input is DocumentTypesEnum {
  return Object.keys(DocumentTypesEnum).includes(input);
}
export function isIdentityType(
  input: GeneralTypes | string,
): input is IdentityTypesEnum {
  return Object.keys(IdentityTypesEnum).includes(input);
}

export interface Document {
  type: DocumentTypes | IdentityTypes;
  required: boolean;
  isUploaded: boolean;
}

export type FilesUpload = {
  [key: string]: File;
};
