import {
  CategoryWithPermissions,
  LinkWithPermissions,
  SidenavLinkWithPermission,
} from '../types/AuthNav';

export const useAuthLayout = ({
  isAuthorizedTo,
  SIDENAV_LINKS,
}: {
  SIDENAV_LINKS: SidenavLinkWithPermission[];
  isAuthorizedTo: (
    requiredPermissions: string[],
    allPermisionsRequired?: boolean,
  ) => boolean;
}) => {
  const isCategory = (item: LinkWithPermissions | CategoryWithPermissions) => {
    return !!(item as CategoryWithPermissions).links;
  };

  const isAuthorizedLink = (link: LinkWithPermissions) => {
    if (!link.requiredPermissions?.length) return true;

    return isAuthorizedTo(link.requiredPermissions, link.allPermisionsRequired);
  };

  const getCategoryWithAuthorizedLinks = (
    category: CategoryWithPermissions,
  ) => {
    const authorizedLinks = category.links.filter(isAuthorizedLink);
    return { ...category, links: authorizedLinks };
  };

  const getItemWithoutPermissionsInfo = (
    item: LinkWithPermissions | CategoryWithPermissions,
  ) => {
    if (isCategory(item)) {
      const category = item as CategoryWithPermissions;
      const newLinks = category.links.map((link) => ({
        name: link.name,
        path: link.path,
      }));
      return { ...item, links: newLinks };
    } else {
      const link = item as LinkWithPermissions;
      return { name: link.name, path: link.path, icon: link.icon };
    }
  };

  const getSidenavAuthorizedLinks = () => {
    const filteredItems = SIDENAV_LINKS.reduce((list, item) => {
      if (isCategory(item)) {
        const categoryWithFilteredLinks = getCategoryWithAuthorizedLinks(
          item as CategoryWithPermissions,
        );

        if (!categoryWithFilteredLinks.links.length) return list;

        return [...list, categoryWithFilteredLinks];
      }

      if (isAuthorizedLink(item as LinkWithPermissions)) {
        return [...list, item];
      }

      return list;
    }, [] as SidenavLinkWithPermission[]);

    return filteredItems.map(getItemWithoutPermissionsInfo);
  };

  return {
    getSidenavAuthorizedLinks,
  };
};
